import React from 'react'
import { Link } from '@reach/router'

export default ({
  success,
  title = '',
  message = null,
  className = '',
  error,
  to,
  ...props
}) => {
  const Element = ({ to, ...props }) =>
    to ? <Link to={to} {...props} /> : <div {...props} />

  let messageToDisplay = message || (success ? 'Online' : 'Offline')
  let tagClass = success ? 'success' : 'warning'

  if (error) {
    messageToDisplay = `Error ${message}`
    tagClass = 'danger'
  }

  return (
    <Element
      to={to}
      className={`tags has-addons taCenter ${className}`}
      {...props}
    >
      <span className="tag">{title}</span>
      <span className={`tag is-${tagClass}`}>{messageToDisplay}</span>
    </Element>
  )
}
