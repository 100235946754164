import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setUserData, setUserID, setUserEmail } from '../redux/userSlice'
import { setAllowQueuePrefixSelect } from '../redux/queueSlice'
import { getDatabase } from '../firebase'

const User = ({ user }) => {
  const dispatch = useDispatch()
  const userID = useSelector(state => state.user.uid)

  useEffect(() => {
    if (user && user?.uid !== userID) {
      dispatch(setUserID(user.uid))
      dispatch(setUserEmail(user.email))
    }
  }, [user, dispatch, userID])

  useEffect(() => {
    const getUserData = async () => {
      const userData = await getDatabase({ path: `/users/${userID}` })
      return userData
    }

    if (userID) {
      getUserData().then(userData => {
        dispatch(setUserData(userData))
        dispatch(setAllowQueuePrefixSelect(userData.allowQueuePrefixSelect))
      })
    }
  }, [userID, dispatch])

  return null
}

export default User
