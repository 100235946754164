import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import localForage from 'localforage'

import user from './userSlice'
import notification from './notificationSlice'
import queue from './queueSlice'
import predictionSettings from './predictionSettingsSlice'
import predictionSummaries from './predictionSummariesSlice'
import predictionAnnotations from './predictionAnnotationsSlice'
import datasets from './datasetsSlice'
import annotation from './annotationSlice'
import thumbnails from './thumbnailsSlice'

const createPersistedReducer = ({ key, reducer, config = {} }) => {
  const persistConfig = {
    key,
    storage: localForage,
    ...config,
  }

  const persistedReducer = persistReducer(persistConfig, reducer)
  return persistedReducer
}

const rootReducer = combineReducers({
  user,
  notification,
  queue: createPersistedReducer({
    reducer: queue,
    key: 'queue',
    config: {
      blacklist: ['queuePrefixOptions'],
    },
  }),
  predictionSettings: createPersistedReducer({
    reducer: predictionSettings,
    key: 'predictionSettings',
  }),
  predictionSummaries: createPersistedReducer({
    reducer: predictionSummaries,
    key: 'predictionSummaries',
  }),
  datasets: createPersistedReducer({
    reducer: datasets,
    key: 'datasets',
  }),
  annotation: createPersistedReducer({
    reducer: annotation,
    key: 'annotation',
  }),
  thumbnails: createPersistedReducer({
    reducer: thumbnails,
    key: 'thumbnails',
  }),
  predictionAnnotations,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
})
export const persistor = persistStore(store)
