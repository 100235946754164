import _ from 'lodash'
import pMap from 'p-map'

import { downloadGCSFile, getDatabase, getUserToken } from './firebase'
import {
  checkFramesAreFromSingleVideo,
  isQueueNameValid,
  parseSplitVideoFilename,
} from './utils'

const { NODE_ENV } = process.env
const isDev = NODE_ENV === 'development'

const firebaseFunctionsEndpoint = isDev
  ? `http://localhost:5000/glow-gu/us-central1`
  : `https://us-central1-glow-gu.cloudfunctions.net`

export const serverRequest = async ({
  serverUrl = 'https://fishdetection-ssb.australiaeast.cloudapp.azure.com',
  action,
  user,
  autostartServer = false,
  body = {},
}) => {
  if (autostartServer) {
    const isServerConnected = await checkIsServerConnected()
    if (!isServerConnected) {
      await startServerSync({ user })
    }
  }
  try {
    console.log(`Requesting ${serverUrl} ${action}`)
    const token = await getUserToken({ user })
    const res = await fetch(`${serverUrl}/${action}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': ' application/json',
      },
      body: JSON.stringify(body),
    })
      .then(res => res.json())
      .then(res => {
        if (res.statusCode !== 200) {
          throw new Error(res.message)
        }
        return res.data
      })
    return res
  } catch (e) {
    console.error(e.message)
    throw new Error(e)
  }
}

export const splitVideo = async ({
  user,
  bucket,
  videoPath, // e.g. /ft-annotation-editor/inference/CastawaysBeachG1C-BartailFlathead-B.mp4
  outputPath, // e.g. /ft-annotation-editor/inference/output_dir
  fps = 25,
  datasetID, // optional, to add image references
}) => {
  const token = await getUserToken({ user })
  return fetch(`${firebaseFunctionsEndpoint}/app/splitVideo`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      bucket,
      videoPath,
      outputPath,
      fps,
      datasetID,
    }),
  })
}

export const updateDatabaseUserCounts = async ({
  user,
  datasetID, // optional, to add image references
}) => {
  const token = await getUserToken({ user })
  return fetch(`${firebaseFunctionsEndpoint}/app/updateDatabaseUserCounts`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      datasetID,
    }),
  })
}

export const processPredictionResults = async ({
  user,
  trainingSessionID,
  filename,
  predictionID,
  confidenceThreshold,
  inputConfidenceFilter,
  seqLinkLength,
  trackerStaleCount,
  trackerMaxSustain,
}) => {
  const token = await getUserToken({ user })
  return fetch(`${firebaseFunctionsEndpoint}/app/processPredictionResults`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      trainingSessionID,
      filename,
      predictionID,
      confidenceThreshold,
      inputConfidenceFilter,
      seqLinkLength,
      trackerStaleCount,
      trackerMaxSustain,
    }),
  }).then(res => res.json())
}

export const processAndDownloadWorkspacePredictionResults = async ({
  user,
  predictionFilePaths,
  predictionSettings,
  workspace,
  onProgress = console.log,
}) => {
  let total = predictionFilePaths.length
  let numberCompleted = 0
  let progress = 0
  // reset progress to 0
  onProgress(progress)

  const incrementProgress = () => {
    numberCompleted += 1
    progress = numberCompleted / total
    onProgress(progress)
  }

  const token = await getUserToken({ user })
  // Request single processing in parallel
  const requestProcessSinglePredictionResults = async predictionFilePath => {
    const {
      fileKey,
      predictionSettings,
      predictionProcessingSettings,
      resultsFilePath,
      slices,
    } = predictionFilePath

    const { filePath } = await processAndDownloadSinglePredictionResults({
      user,
      predictedFilename: fileKey.filename,
      resultsFilePath,
      slices,
      predictionSettings,
      predictionProcessingSettings,
      skipDownload: true,
    })

    return { filePath }
  }

  const runProcessRequest = async props => {
    const { filePath } = await requestProcessSinglePredictionResults(props)
    incrementProgress()
    return filePath
  }

  const predictionCsvFilePaths = await pMap(
    predictionFilePaths,
    runProcessRequest,
    { concurrency: 10 }
  )

  // Request zip file
  const { filePath } = await fetch(
    `${firebaseFunctionsEndpoint}/app/combineAndDownloadWorkspacePredictionResults`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        predictionSettings,
        workspace,
        predictionCsvFilePaths,
      }),
    }
  ).then(res => res.json())

  if (filePath) {
    await downloadGCSFile({ filePath })
  } else {
    console.error(
      `No filePath received from combineAndDownloadWorkspacePredictionResults()`
    )
  }
}

export const processAndDownloadSinglePredictionResults = async ({
  user,
  predictedFilename,
  resultsFilePath,
  resultsFilename,
  slices,
  predictionSettings,
  predictionProcessingSettings,
  skipDownload = false,
}) => {
  const token = await getUserToken({ user })
  const { filePath } = await fetch(
    `${firebaseFunctionsEndpoint}/app/processAndDownloadSinglePredictionResults`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        predictedFilename,
        resultsFilePath,
        resultsFilename,
        slices,
        predictionSettings,
        predictionProcessingSettings,
      }),
    }
  ).then(res => res.json())

  if (!skipDownload) {
    if (filePath) {
      await downloadGCSFile({ filePath })
    } else {
      console.error(
        `No filePath received from processAndDownloadSinglePredictionResults()`
      )
    }
  }
  return { filePath }
}

export const downloadSinglePredictionResults = async ({
  user,
  predictedFilename,
  resultsFilePath,
  resultsFilename,
  slices,
  predictionSettings,
}) => {
  const token = await getUserToken({ user })
  const { filePath } = await fetch(
    `${firebaseFunctionsEndpoint}/app/downloadSinglePredictionResults`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        predictedFilename,
        resultsFilePath,
        resultsFilename,
        slices,
        predictionSettings,
      }),
    }
  ).then(res => res.json())

  if (filePath) {
    await downloadGCSFile({ filePath })
  } else {
    console.error(`No filePath received from downloadSinglePredictionResults()`)
  }
}

export const requestSaveWorkspaceToDatasetIteration = async ({
  user,
  datasetID,
}) => {
  const token = await getUserToken({ user })
  return fetch(
    `${firebaseFunctionsEndpoint}/app/saveWorkspaceToDatasetIteration`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        datasetID,
        userID: user.uid,
      }),
    }
  )
    .then(res => res.json())
    .catch(error => {
      return {
        error,
      }
    })
}

export const datasetImagesAction = async ({
  user,
  sourceDataset,
  sourceDatasetIteration,
  destinationDataset,
  includeAnnotations,
  originalString,
  replacementString,
  filenames,
  batchSize = 50,
  action, // move copy remove slice rename
}) => {
  const token = await getUserToken({ user })
  console.log(
    `${_.startCase(action)} ${filenames.length} files from ${sourceDataset} ${
      destinationDataset ? `to ${destinationDataset}` : ''
    } requested`
  )

  console.log({ filenames })

  if (!['move', 'copy', 'remove', 'slice', 'rename'].includes(action)) {
    return console.error(`Action is not supported`, { action })
  }

  if (action === 'slice') {
    const getParsedVideoFilenames = filenames => {
      const videoNames = filenames.map(
        filename => parseSplitVideoFilename(filename)?.videoName
      )
      const uniqVideoNames = [...new Set(videoNames)]
      return uniqVideoNames
    }

    const areFramesFromSingleVideo = checkFramesAreFromSingleVideo(filenames)
    const videoFilenames = getParsedVideoFilenames(filenames)
    const numberOfVideos = videoFilenames.length
    batchSize = filenames.length

    if (!areFramesFromSingleVideo || numberOfVideos > 1) {
      return console.warn(
        'Cannot save frames as new slice, not a sequential selection'
      )
    }

    if (
      !window.confirm(
        `Please confirm intention to split ${filenames.length} frames from ${videoFilenames[0]} to a new video`
      )
    ) {
      return
    }
  }

  // batch requests
  const filenameBatches = _.chunk(filenames, batchSize)

  const fetchRequest = async filenames => {
    console.log(
      `Requesting ${firebaseFunctionsEndpoint}/app/${action}DatasetImages with ${filenames.length} files`
    )
    const response = await fetch(
      `${firebaseFunctionsEndpoint}/app/${action}DatasetImages`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          sourceDataset,
          sourceDatasetIteration,
          destinationDataset,
          filenames,
          action,
          includeAnnotations,
          originalString,
          replacementString,
        }),
      }
    ).then(res => res.text())
    return response
  }

  const result = await pMap(filenameBatches, fetchRequest, { concurrency: 1 })
  console.log(result)
  return result
}

export const queueRequest = async ({
  serverUrl = 'https://ft-prediction-queue.azurewebsites.net/api',
  // serverUrl = 'http://localhost:7071/api',
  action, // [listQueueItems, createQueueItem]
  user,
  body = {},
}) => {
  try {
    console.log(`Requesting ${serverUrl} ${action}`, body)
    if (body?.queueName) {
      const validQueueName = isQueueNameValid(body?.queueName)
      if (!validQueueName) {
        throw new Error(`Invalid queue name ${body?.queueName}`)
      }
    }
    const token = await getUserToken({ user })
    const res = await fetch(`${serverUrl}/${action}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then(res => res.json())
      .then(res => {
        if (res.statusCode !== 200) {
          throw new Error(res.message)
        }
        return res.data
      })
    return res
  } catch (e) {
    console.error(e)
  }
}

export const vmAction = async ({
  vmName,
  vmType,
  vmResourceGroup,
  vmSubscriptionID,
  action,
  user,
}) => {
  const token = await getUserToken({ user })
  const status = await fetch('/.netlify/functions/vm', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      action,
      vmName,
      vmType,
      vmResourceGroup,
      vmSubscriptionID,
    }),
  })
    .then(res => res.json())
    .catch(e => console.error(e))
  return status
}

const checkIsServerConnected = async () => {
  const serverConnection = await getDatabase({ path: 'serverConnection' })
  const isServerConnected = _.get(serverConnection, 'status') === 'connected'
  // const serverStatusTimestamp = _.get(serverConnection, 'timestamp')
  // const serverTime = await getTimestampMs()
  // const serverStatusAge = serverTime - serverStatusTimestamp
  // const isStale = serverStatusAge && serverStatusAge > 120 * 1000
  return isServerConnected
}

const startServerSync = async ({ user, interval = 120000, timeout = 300000 }) =>
  vmAction({ action: 'start', user })
    .then(
      () =>
        new Promise((resolve, reject) => {
          let startTime = new Date()

          console.log(`Listening to server status`)
          const pollInterval = setInterval(async () => {
            const timeDifference = new Date() - startTime

            const isServerConnected = await checkIsServerConnected()

            if (isServerConnected) {
              clearInterval(pollInterval)
              console.log(`Server start complete`)
              resolve(isServerConnected)
            }
            if (timeDifference >= timeout) {
              clearInterval(pollInterval)
              console.error(`Server start timed out`)
              reject(new Error('Server start timed out'))
            }
            if (timeDifference >= interval) {
              // start again
              console.log(`Server start timed out, attempting start again`)
              startTime = new Date()
              return await vmAction({ action: 'start', user })
            }
          }, 5000)
        })
    )
    .catch(e => {
      throw new Error(e)
    })

export const requestImportWorkspaceAnnotations = async ({
  datasetID,
  csvString,
  deleteExistingAnnotations = true,
  user,
}) => {
  const token = await getUserToken({ user })
  const res = await fetch(
    `${firebaseFunctionsEndpoint}/app/importDatasetWorkspaceAnnotations`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        datasetID,
        csvString,
        deleteExistingAnnotations,
        userID: user.uid,
      }),
    }
  )
  return res
}
