import React from 'react'
import _ from 'lodash'
import X from 'react-feather/dist/icons/x'

import Loading from './Loading'
import DatasetSummary from './DatasetSummary'
import './VideoInput.css'

const DatasetSummaryPage = ({ datasetID, iterationID, closePopup }) => {
  return (
    <div>
      {datasetID && iterationID ? (
        <div className="section">
          <div className="container">
            <X className="VideoInput--Close" onClick={closePopup} />
            <DatasetSummary datasetID={datasetID} iterationID={iterationID} />
          </div>
        </div>
      ) : (
        <Loading fullscreen />
      )}
    </div>
  )
}

export default DatasetSummaryPage
