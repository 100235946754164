import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'user',
  initialState: {
    email: null,
    uid: null,
    userData: {},
  },
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload
    },
    setUserEmail: (state, action) => {
      state.email = action.payload
    },
    setUserID: (state, action) => {
      state.uid = action.payload
    },
  },
})

export const selectUserRole = state => state.user.userData?.role
export const selectUserID = state => state.user.uid

export const { setUserData, setUserEmail, setUserID } = userSlice.actions

export default userSlice.reducer
