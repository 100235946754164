import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {
  segmentationMode: false,
  labels: [],
  annotationsClipboard: [],
  annotationsClipboardFilename: undefined,
}

const annotationSlice = createSlice({
  name: 'annotation',
  initialState,
  reducers: {
    toggleSegmentationMode: state => {
      return {
        segmentationMode: !state.segmentationMode,
      }
    },
    addLabel: (state, action) => {
      const { labelName } = action.payload
      if (labelName) {
        const labels = [
          ...state.labels,
          {
            labelName,
          },
        ]
        const labelsSorted = _.sortBy(labels, 'labelName')
        return {
          labels: labelsSorted,
        }
      }
    },
    removeLabel: (state, action) => {
      const { labelName } = action.payload
      const labels = state.labels.filter(label => label.labelName !== labelName)
      return { labels }
    },
    addLabels: (state, action) => {
      const labelNames = action.payload.labelNames
      const newLabels = labelNames.map(labelName => ({ labelName }))
      const labelsSorted = _.chain([...state.labels, ...newLabels])
        .uniqBy('labelName')
        .sortBy('labelName')
        .value()
      return { labels: labelsSorted }
    },
    setAnnotationsClipboard: (state, action) => {
      state.annotationsClipboard = action.payload.annotations
      state.annotationsClipboardFilename = action.payload.filename
    },
    clearAnnotationsClipboard: (state, action) => {
      state.annotationsClipboard = []
      state.filename = undefined
    },
  },
})

export const {
  toggleSegmentationMode,
  addLabel,
  removeLabel,
  addLabels,
  setAnnotationsClipboard,
  clearAnnotationsClipboard,
} = annotationSlice.actions

export default annotationSlice.reducer
