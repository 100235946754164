import _ from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'

import { Link } from '@reach/router'
import Tippy from '@tippy.js/react'

import {
  selectAllowQueuePrefixSelect,
  selectQueuePrefixObject,
  selectQueuePrefixOptions,
  setQueuePrefix,
} from '../redux/queueSlice'
import { vmAction } from '../request'
import {
  formatDate,
  stopJob,
  useQueueItems,
  useServers,
  useUserIsAdmin,
} from '../utils'
import Identicon from './Identicon'
import Loading from './Loading'
import NotAuthorised from './NotAuthorised'
import ServerStatusTags from './ServerStatusTags'

const getJobUrl = ({ job, queueName }) => {
  let jobUrl
  switch (queueName) {
    case 'training':
      jobUrl = `/training/${job.datasetID}/${job.trainingSessionID}`
      break
    case 'evaluation':
      jobUrl = `/training/${job.datasetID}/${job.trainingSessionID}/${job.evaluationID}`
      break
    case 'prediction':
      jobUrl = `/predict/${job.datasetID}/${job.trainingSessionID}`
      break
    default:
      break
  }
  return jobUrl
}

const ServerStatus = props => {
  const { user } = props
  const userIsAdmin = useUserIsAdmin()
  if (!user) return <Loading />
  if (userIsAdmin) {
    return <ServerStatusAuthed {...props} />
  } else {
    return <NotAuthorised />
  }
}

const ServerStatusAuthed = ({ user, location, navigate }) => {
  const queuePrefixObject = useSelector(selectQueuePrefixObject)
  const servers = useServers({
    hideDisabled: false,
    filterByGroup: queuePrefixObject?.label,
  })
  const showQueuePrefixSelect = useSelector(selectAllowQueuePrefixSelect)

  const { queueItems: evaluationQueueItems } = useQueueItems({
    user,
    queueSuffix: `evaluation-queue`,
    servers,
  })
  const { queueItems: predictionQueueItems } = useQueueItems({
    user,
    queueSuffix: `prediction-queue`,
    servers,
  })
  const { queueItems: trainingQueueItems } = useQueueItems({
    user,
    queueSuffix: `training-queue`,
    servers,
  })

  const [queueItems, setQueueItems] = useState({})

  useEffect(() => {
    const queueItems = {
      evaluation: evaluationQueueItems,
      prediction: predictionQueueItems,
      training: trainingQueueItems,
    }
    setQueueItems(prevQueueItems => {
      if (!_.isEqual(prevQueueItems, queueItems)) {
        return queueItems
      } else {
        return prevQueueItems
      }
    })
  }, [evaluationQueueItems, predictionQueueItems, trainingQueueItems, servers])

  const startServer = ({
    serverName,
    serverType,
    serverResourceGroup,
    serverSubscriptionID,
  }) =>
    window.confirm(`Are you sure you wish to start ${serverName}?`) &&
    vmAction({
      action: 'start',
      vmName: serverName,
      vmType: serverType,
      vmResourceGroup: serverResourceGroup,
      vmSubscriptionID: serverSubscriptionID,
      user,
    }).then(() => {
      // _.delay(updateStatus, 1000)
    })

  const restartServer = ({
    serverName,
    serverType,
    serverResourceGroup,
    serverSubscriptionID,
  }) =>
    window.confirm(`Are you sure you wish to restart ${serverName}?`) &&
    vmAction({
      action: 'restart',
      vmName: serverName,
      vmType: serverType,
      vmResourceGroup: serverResourceGroup,
      vmSubscriptionID: serverSubscriptionID,
      user,
    }).then(() => {
      // _.delay(updateStatus, 1000)
    })

  const deallocateServer = ({
    serverName,
    serverType,
    serverResourceGroup,
    serverSubscriptionID,
  }) =>
    window.confirm(`Are you sure you STOP ${serverName}?`) &&
    vmAction({
      action: 'deallocate',
      vmName: serverName,
      vmType: serverType,
      vmResourceGroup: serverResourceGroup,
      vmSubscriptionID: serverSubscriptionID,
      user,
    })

  return (
    <div className="section">
      <div className="header" style={{ marginBottom: 20 }}>
        <button
          className="button is-small is-info"
          onClick={e => {
            e.preventDefault()
            navigate(_.get(location, 'state.parentPath', '/'))
          }}
        >
          Back
        </button>
      </div>

      {user && (
        <ServersTable
          servers={servers}
          startServer={startServer}
          restartServer={restartServer}
          deallocateServer={deallocateServer}
          stopJob={({ jobId }) => stopJob({ jobId, servers, user })}
          user={user}
          showQueuePrefixSelect={showQueuePrefixSelect}
        />
      )}

      {user &&
        _.map(queueItems, (queueItems, queueName) => (
          <QueueTable
            key={queueName}
            queueName={queueName}
            queueItems={queueItems}
            stopJob={({ jobId }) => stopJob({ jobId, servers, user })}
          />
        ))}
    </div>
  )
}

const ServersTable = ({
  servers,
  startServer,
  restartServer,
  deallocateServer,
  stopJob,
  user,
  showQueuePrefixSelect,
}) => {
  const filterByDisabled = server => server.disabled
  const enabledServers = servers.filter(server => !filterByDisabled(server))
  const disabledServers = servers.filter(filterByDisabled)
  servers = [...enabledServers, ...disabledServers]
  return (
    <div className="container">
      <h4 className="title is-5">Servers</h4>
      {!servers?.length && <Loading />}
      {user && showQueuePrefixSelect && <QueuePrefixSelect />}
      {!!servers?.length && (
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>Server Name</th>
              <th>Version</th>
              <th>Status</th>
              <th>Current Job</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {servers.map(
              ({
                name: serverName,
                url: serverUrl,
                currentJobID,
                serverConnection,
                serverType,
                serverResourceGroup,
                serverSubscriptionID,
                version,
                disabled,
                isRegularInstance,
              }) => {
                const isConnected = serverConnection?.status === 'connected'
                return (
                  <tr key={`${serverResourceGroup}/${serverName}`}>
                    <td>
                      {serverResourceGroup}/{serverName}
                    </td>
                    <td>{version && <code>{version}</code>}</td>
                    <td>
                      <ServerStatusTags
                        simple
                        showBackButton={false}
                        serverConnection={serverConnection}
                        message={
                          serverConnection?.status &&
                          _.startCase(serverConnection.status)
                        }
                      />
                    </td>
                    <td>
                      {isConnected && currentJobID && (
                        <span className="tag">
                          <Identicon seed={currentJobID} /> &ensp;{currentJobID}
                        </span>
                      )}
                    </td>
                    <td>
                      {false && serverConnection && currentJobID && !disabled && (
                        <button
                          className="button is-warning is-small"
                          onClick={() => stopJob({ jobId: currentJobID })}
                          style={{ marginRight: 10 }}
                        >
                          Stop current job
                        </button>
                      )}
                      {!disabled && (
                        <Fragment>
                          <Tippy
                            content="As this server costs much more to run, please only use this instance as a last-resort. 💸"
                            enabled={!!isRegularInstance}
                          >
                            <button
                              className={`button is-small is-info ${
                                isConnected || isRegularInstance
                                  ? 'is-warning'
                                  : ''
                              }`}
                              style={{ marginRight: 10 }}
                              onClick={() => {
                                if (isConnected) {
                                  restartServer({
                                    serverName,
                                    serverType,
                                    serverResourceGroup,
                                    serverSubscriptionID,
                                  })
                                } else {
                                  startServer({
                                    serverName,
                                    serverType,
                                    serverResourceGroup,
                                    serverSubscriptionID,
                                    isRegularInstance,
                                  })
                                }
                              }}
                            >
                              {isConnected ? 'Restart server' : 'Start server'}
                            </button>
                          </Tippy>
                          <button
                            className="button is-small is-danger"
                            onClick={() =>
                              deallocateServer({
                                serverName,
                                serverType,
                                serverResourceGroup,
                                serverSubscriptionID,
                              })
                            }
                          >
                            Shutdown server
                          </button>
                        </Fragment>
                      )}
                    </td>
                  </tr>
                )
              }
            )}
          </tbody>
        </table>
      )}
    </div>
  )
}

const QueueTable = ({ queueName, queueItems, stopJob }) => (
  <div className="container" style={{ marginTop: 50 }}>
    <h4 className="title is-5" style={{ marginBottom: 10 }}>
      {_.startCase(queueName)} Queue
    </h4>
    <table className="table is-fullwidth">
      <thead>
        <tr>
          <th>Timestamp</th>
          <th>Job ID</th>
          <th>Details</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {queueItems?.length ? (
          queueItems.map(queueItem => (
            <QueueRow
              key={queueItem.messageId}
              queueItem={queueItem}
              stopJob={stopJob}
              queueName={queueName}
            />
          ))
        ) : (
          <tr>
            <td>No jobs in {_.startCase(queueName)} Queue 🏖</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
)

const QueueRow = ({ queueItem, stopJob, queueName }) => {
  const { messageId, insertedOn, isActive } = queueItem
  const { jobId, ...job } = queueItem.job || {}

  const jobDetails = _.map(job, (val, key) => `${key}: ${val}`).join('\n')
  const jobUrl = getJobUrl({ job, queueName })

  return (
    <tr key={messageId}>
      <td>{formatDate(insertedOn)}</td>
      <td>
        <div className="tags">
          <span className="tag">
            <Identicon seed={jobId} />
            &ensp;{jobId}
          </span>
          {isActive && <span className="tag is-warning">Running</span>}
        </div>
      </td>
      <td>
        <pre style={{ fontSize: 10 }}>
          <code>{jobDetails}</code>
        </pre>
      </td>
      <td>
        <Link className="button is-info is-small is-outlined" to={jobUrl}>
          View
        </Link>
        {false && isActive && (
          <button
            className="button is-danger is-small"
            onClick={() => stopJob({ jobId })}
            style={{ marginLeft: 10 }}
          >
            Stop
          </button>
        )}
      </td>
    </tr>
  )
}

const QueuePrefixSelect = () => {
  const queuePrefixObject = useSelector(selectQueuePrefixObject)
  const queuePrefixOptions = useSelector(selectQueuePrefixOptions)
  const dispatch = useDispatch()

  return (
    <div>
      <p className="help">
        Each server group has a separate set of queues. Select a specific server
        group to submit jobs to this queue.
      </p>
      <div style={{ marginTop: 10, maxWidth: 400 }}>
        <Select
          options={queuePrefixOptions}
          value={queuePrefixObject}
          onChange={({ value }) => {
            dispatch(setQueuePrefix(value))
          }}
        />
      </div>
    </div>
  )
}

export default ServerStatus
