import React from 'react'
import Spinner from './Spinner'

export default ({ fullscreen, style = {}, message }) => {
  let defaultStyle = {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    flexDirection: 'column',
  }
  if (fullscreen) {
    defaultStyle = {
      ...defaultStyle,
      height: '100vh',
      width: '100vw',
    }
  }
  return (
    <div style={{ ...defaultStyle, ...style }} data-testid="Loading">
      <Spinner />
      {!!message && (
        <div className="message is-info" style={{ marginTop: 20 }}>
          <div className="message-body">{message}</div>
        </div>
      )}
    </div>
  )
}
