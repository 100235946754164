import React from 'react'
import { Link } from '@reach/router'

import StatusTag from './StatusTag'
import TagGroup from './TagGroup'
import {
  useAreAnyServersConnected,
  useUserIsAdmin,
  useAppVersion,
} from '../utils'
import { useSelector } from 'react-redux'
import {
  selectAllowQueuePrefixSelect,
  selectQueuePrefix,
  selectQueuePrefixOptions,
  selectQueuePrefixObject,
} from '../redux/queueSlice'

const ServerStatusTags = ({
  to = '../',
  showBackButton = true,
  simple = false,
  showQueuePrefixSelected = true,
  parentPath,
  style = {},
  serverConnection,
  message,
  className = '',
}) => {
  const appVersion = useAppVersion()
  const areAnyServersConnected = useAreAnyServersConnected()
  const userIsAdmin = useUserIsAdmin()

  const queuePrefixObject = useSelector(selectQueuePrefixObject)
  const allowQueuePrefixSelect = useSelector(selectAllowQueuePrefixSelect)
  const queuePrefixOptions = useSelector(selectQueuePrefixOptions)

  if (simple) {
    const success =
      serverConnection === null || serverConnection === undefined
        ? areAnyServersConnected
        : serverConnection?.status === 'connected'
    return (
      <div style={{ display: 'flex', alignItems: 'flex-start', ...style }}>
        <StatusTag
          success={success}
          title={'Server Status'}
          to="/status"
          state={{ parentPath }}
          message={message}
        />
        {userIsAdmin && showQueuePrefixSelected && allowQueuePrefixSelect && (
          <div className="control" style={{ marginLeft: 10 }}>
            <Link to="/status" state={{ parentPath }}>
              <TagGroup
                title={'Server Group'}
                value={queuePrefixObject?.label}
                valueStyle={{ background: queuePrefixObject?.color }}
              />
            </Link>
          </div>
        )}
      </div>
    )
  }

  return (
    <section
      className={`section ${className}`}
      style={{
        paddingTop: 0,
        paddingBottom: 0,
      }}
    >
      <nav
        className="container"
        style={{
          padding: '1rem 0',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          ...style,
        }}
      >
        <div className="field is-grouped is-grouped-multiline">
          {showBackButton && (
            <div className="control">
              <Link
                className="button is-small is-info"
                to={to}
                data-testid="ServerStatusTags--Back"
              >
                Back
              </Link>
            </div>
          )}
          {userIsAdmin && (
            <div className="control">
              <StatusTag
                success={areAnyServersConnected}
                title={'Server Status'}
                to="/status"
                state={{ parentPath }}
                message={areAnyServersConnected && serverConnection?.status}
              />
            </div>
          )}
          {userIsAdmin && showQueuePrefixSelected && allowQueuePrefixSelect && (
            <div className="control">
              <Link to="/status" state={{ parentPath }}>
                <TagGroup
                  title={'Server Group'}
                  value={queuePrefixObject?.label}
                  valueStyle={{ background: queuePrefixObject?.color }}
                />
              </Link>
            </div>
          )}
          <div className="control">
            <TagGroup
              title={'App Version'}
              value={appVersion}
              valueClassname="is-dark"
              valueStyle={{ fontWeight: 'bold' }}
            />
          </div>
        </div>
      </nav>
    </section>
  )
}

export default ServerStatusTags
