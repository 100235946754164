import React from 'react'

import { useDataset } from '../firebase'
import { ImportDatasetModal } from './ImportDatasetModal'

const DatasetImport = ({ datasetID, user, closePopup }) => {
  const dataset = useDataset({ datasetID })

  function handleSetShowModal(val) {
    if (val === false) {
      closePopup()
    }
  }

  return (
    <div className="container">
      <section className="section">
        {!!user && !!dataset && (
          <ImportDatasetModal
            setShowModal={handleSetShowModal}
            datasetID={datasetID}
            datasetSlug={dataset?.slug}
            user={user}
          />
        )}
      </section>
    </div>
  )
}

export default DatasetImport
