import React, { useState, useEffect } from 'react'
// import { useSelector } from 'react-redux'
import _ from 'lodash'
// import Papa from 'papaparse'
// import { saveAs } from 'file-saver'

import {
  parseSplitVideoFilename,
  useDatasetAnnotations,
  useDatasetImages,
} from '../utils'
// import { useDatabase } from '../firebase'
import Loading from './Loading'

const DatasetSummary = ({ datasetID, iterationID }) => {
  const [filenamesByVideo, setFilenamesByVideo] = useState({})

  const {
    annotatedFilenames,
    isLoading: datasetAnnotationsLoading,
  } = useDatasetAnnotations({
    datasetID,
    iterationID,
    getAnnotatedFilenames: true,
  })

  const { allImages } = useDatasetImages({
    datasetID,
    annotatedImages: annotatedFilenames,
  })

  // const datasetSlug = useDatabase({
  //   path: datasetID && `datasets/${datasetID}/slug`,
  // })

  useEffect(() => {
    // setFilenamesByVideo
    if (allImages) {
      const filenamesByVideo = _.chain(allImages)
        .filter(Boolean)
        .uniq()
        .groupBy(filename =>
          _.get(parseSplitVideoFilename(filename), 'videoName', 'Ungrouped')
        )
        .value()
      setFilenamesByVideo(filenamesByVideo)
    }
  }, [allImages])

  // TODO: refactor exportVideoListToCsv

  // const exportVideoListToCsv = () => {
  //   const allClasses = _.chain(datasetAnnotations)
  //     .filter(Boolean)
  //     .filter(anno => anno.class)
  //     .map('class')
  //     .uniq()
  //     .sort()
  //     .value()

  //   const getAnnotationsByFilenames = ({ filenames = [], annotations = [] }) =>
  //     annotations
  //       .filter(Boolean)
  //       .filter(anno => anno.class)
  //       .filter(anno => filenames.includes(anno.filename))

  //   const videoList = _.map(filenamesByVideo, (files, videoName) => {
  //     const videoAnnotations = getAnnotationsByFilenames({
  //       filenames: files,
  //       annotations: datasetAnnotations,
  //     })
  //     const classCounts = _.countBy(videoAnnotations, 'class')
  //     const allClassCounts = allClasses.reduce((allClassCounts, className) => {
  //       allClassCounts[className] = classCounts[className]
  //       return allClassCounts
  //     }, {})
  //     return {
  //       datasetID: datasetID,
  //       datasetName: datasetSlug,
  //       videoFilename: videoName,
  //       frames: files.length,
  //       ...allClassCounts,
  //     }
  //   })

  //   const csvFilename = `${datasetID}-uploaded-videos.csv`
  //   const csv = Papa.unparse(videoList)
  //   const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' })
  //   saveAs(blob, csvFilename)
  // }

  const videoFilenames = _.keys(filenamesByVideo).sort()

  return (
    <div className="DatasetSummary">
      {datasetAnnotationsLoading || !videoFilenames.length ? (
        <Loading style={{ margin: '25px auto' }} />
      ) : (
        <nav
          className="panel"
          style={{
            maxWidth: 800,
            margin: '50px auto',
            maxHeight: 600,
            overflowY: 'scroll',
          }}
        >
          <div
            className="panel-heading has-text-weight-semibold"
            style={{ display: 'flex', position: 'sticky', top: 0 }}
          >
            <span>Videos uploaded: {videoFilenames.length}</span>
            {/* <button
                className="button"
                onClick={exportVideoListToCsv}
                style={{ marginLeft: 'auto' }}
              >
                Export CSV
              </button> */}
          </div>

          {videoFilenames.map(videoName => (
            <div className="panel-block" key={videoName}>
              <span>{videoName}</span>
              <span style={{ marginLeft: 'auto', flexShrink: 0 }}>
                {filenamesByVideo[videoName].length} frames
              </span>
            </div>
          ))}
        </nav>
      )}
    </div>
  )
}

export default DatasetSummary
