import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  message: '',
  type: 'info',
  loading: false,
  disableClose: false,
  progress: null,
  active: false,
}

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    clearNotification: state => {
      return {
        ...initialState,
      }
    },
    setNotification: (state, action) => {
      return {
        active: true,
        ...action.payload,
      }
    },
  },
})

export const { clearNotification, setNotification } = notificationSlice.actions

export default notificationSlice.reducer
