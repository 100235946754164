import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {
  visibleVideoNames: {
    // 'datasetID': [
    //   'videoName'
    // ]
  },
  showAllThumbnails: false,
}

const thumbnailsSlice = createSlice({
  name: 'thumbnails',
  initialState,
  reducers: {
    toggleVideoVisibility: (state, action) => {
      const { datasetID, videoFilename } = action.payload
      const existingDatasetIDFilenames =
        state.visibleVideoNames?.[datasetID] || []
      const datasetIDFilenames = _.xor(existingDatasetIDFilenames, [
        videoFilename,
      ])
      state = {
        ...state,
        visibleVideoNames: {
          ...state.visibleVideoNames,
          [datasetID]: datasetIDFilenames,
        },
      }
      return state
    },
    toggleShowAllThumbnails: (state, action) => {
      const { datasetID } = action.payload
      const showAllThumbnails = !state.showAllThumbnails
      // Collapse dataset videos if showAllThumbnails == false
      if (!showAllThumbnails) {
        state.visibleVideoNames = {
          ...state.visibleVideoNames,
          [datasetID]: [],
        }
      }
      state.showAllThumbnails = showAllThumbnails
      return state
    },
  },
})

export const {
  toggleVideoVisibility,
  toggleShowAllThumbnails,
} = thumbnailsSlice.actions

export default thumbnailsSlice.reducer
