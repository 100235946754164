import React from 'react'

const TagGroup = ({
  title,
  value,
  titleClassname = '',
  valueClassname = 'is-info',
  style = {},
  titleStyle = {},
  valueStyle = {},
}) => (
  <div className="control" style={style}>
    <div className="tags has-addons">
      <span className={`tag ${titleClassname}`} style={titleStyle}>
        {title}
      </span>
      <span className={`tag ${valueClassname}`} style={valueStyle}>
        {value}
      </span>
    </div>
  </div>
)

export default TagGroup
