import React from 'react'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import posed, { PoseGroup } from 'react-pose'

import { clearNotification } from '../redux/notificationSlice'

const PosedDiv = posed.div({
  enter: {
    y: 0,
    scale: 1,
    opacity: 1,
    delay: 0,
    transition: {
      y: { type: 'spring', stiffness: 1000, damping: 15 },
      scale: { type: 'spring', stiffness: 1000, damping: 15 },
      default: { duration: 300 },
    },
  },
  exit: {
    y: 50,
    scale: 1.2,
    opacity: 0,
    transition: { duration: 150 },
  },
})

const Notification = ({ style = {}, fixed = true }) => {
  const dispatch = useDispatch()
  const notification = useSelector(state => state.notification)

  const {
    message,
    type = 'info',
    loading = false,
    disableClose = false,
    progress = null,
    active = false,
  } = notification

  let className = 'notification'
  if (type === 'error') className += ' is-danger'
  if (type === 'info') className += ' is-info'
  if (type === 'warning') className += ' is-warning'
  if (type === 'success') className += ' is-success'
  if (fixed) {
    style = {
      overflow: 'hidden',
      position: 'fixed',
      right: '2rem',
      top: '2rem',
      zIndex: 10,
      ...style,
    }
  } else {
    style = {
      position: 'relative',
      ...style,
    }
  }

  if (!active) return null

  return (
    <PoseGroup animateOnMount>
      <PosedDiv key={0} animateOnMount className={className} style={style}>
        {(loading || _.isNumber(progress)) && (
          <progress
            className="progress is-small is-info"
            max="1"
            value={progress}
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              borderRadius: 0,
              height: '5px',
            }}
          />
        )}
        {!disableClose && (
          <button
            className="delete"
            onClick={() => dispatch(clearNotification())}
          />
        )}
        {message}
      </PosedDiv>
    </PoseGroup>
  )
}

export default Notification
