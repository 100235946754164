import { createSlice } from '@reduxjs/toolkit'

const queuePrefixOptions = [
  {
    value: 'ft',
    label: 'RGWBFishDetection',
    color: 'lightgrey',
  },
  {
    value: 'dev',
    label: 'DEV',
    color: 'tomato',
  },
]

const initialState = {
  queuePrefix: 'ft',
  queuePrefixObject: queuePrefixOptions[0],
  queuePrefixOptions,
  allowQueuePrefixSelect: true,
}

const queueSlice = createSlice({
  name: 'queue',
  initialState,
  reducers: {
    setQueuePrefix: (state, action) => {
      const queuePrefixValue = action.payload
      const { queuePrefixOptions } = state

      const queuePrefixObject = queuePrefixOptions.find(
        option => option.value === queuePrefixValue
      )
      if (queuePrefixObject) {
        state.queuePrefix = action.payload
        state.queuePrefixObject = queuePrefixObject
      } else {
        console.error(
          `setQueuePrefix called with invalid queuePrefixValue ${queuePrefixValue}`
        )
      }
    },
    setAllowQueuePrefixSelect: (state, action) => {
      state.allowQueuePrefixSelect = action.payload
    },
  },
})

export const selectQueuePrefix = state => state.queue.queuePrefix
export const selectQueuePrefixObject = state => state.queue.queuePrefixObject
export const selectQueuePrefixOptions = state => state.queue.queuePrefixOptions
export const selectAllowQueuePrefixSelect = state =>
  state.queue.allowQueuePrefixSelect

export const { setQueuePrefix, setAllowQueuePrefixSelect } = queueSlice.actions

export default queueSlice.reducer
