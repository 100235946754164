import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {
  //[trainingSessionID]/[filenameKey]/[predictionID]/[configID]: annotations
}

const predictionAnnotationsSlice = createSlice({
  name: 'predictionAnnotations',
  initialState,
  reducers: {
    addPredictionAnnotations: (state, action) => {
      const {
        data,
        trainingSessionID,
        filenameKey,
        predictionID,
        configID,
      } = action.payload
      if (
        data &&
        trainingSessionID &&
        filenameKey &&
        predictionID &&
        configID
      ) {
        state = _.set(
          state,
          `[${trainingSessionID}/${filenameKey}/${predictionID}/${configID}]`,
          data
        )
      } else {
        console.log(`addPredictionAnnotations missing params`, {
          data,
          trainingSessionID,
          filenameKey,
          predictionID,
          configID,
        })
      }
      return state
    },
  },
})

export const { addPredictionAnnotations } = predictionAnnotationsSlice.actions

export default predictionAnnotationsSlice.reducer
