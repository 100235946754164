import React from 'react'
import { Link } from '@reach/router'
import { useSelector } from 'react-redux'

import ServerStatusTags from './ServerStatusTags'

export default () => {
  const user = useSelector(state => state.user)

  return (
    <div>
      <ServerStatusTags parentPath="/datasets" to="../" />
      <section className="section">
        <div className="container">
          <p className="notification">
            User <code>{user?.email}</code> is not authorised to access this
            page. <Link to="/login">Sign out</Link>
          </p>
        </div>
      </section>
    </div>
  )
}
