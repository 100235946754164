import React from 'react'
import _ from 'lodash'
import './ProgressBar.css'

export default ({ progress = 0, style = {}, ...props }) => (
  <progress
    className={'progress is-info'}
    value={progress * 100}
    max={100}
    aria-label="Progress Bar"
    style={style}
    {...props}
  >
    {_.round(progress * 100)}%
  </progress>
)
