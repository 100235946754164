import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  minLinkLength: 1,
  useSeqNMS: true,
  confidenceThreshold: 0.5,
  confidenceThresholdPerSpecies: {},
  inputConfidenceFilter: false,
  trackerStaleThreshold: 0.7,
  trackerStaleCount: 5,
  displayDuration: 2,
  showTrails: true, // adjust this to show anno more/less duration (lower is longer)
  showConfidence: true,
  showTrackerIndex: true,
  showCounts: true,
  lightenMode: true,
  boxOpacity: 1,
  fillOpacity: 0.15,
  isFullscreen: false,
  showPredictionControls: true,
}

const predictionSettingsSlice = createSlice({
  name: 'predictionSettings',
  initialState,
  reducers: {
    updatePredictionSettings: (state, action) => {
      const updates = action.payload
      state = {
        ...state,
        ...updates,
      }
      return state
    },
    togglePredictionSettings: (state, action) => {
      const settingName = action.payload
      console.log({ action })
      const prevValue = state[settingName]
      state = {
        ...state,
        [settingName]: !prevValue,
      }
      return state
    },
  },
})

export const {
  updatePredictionSettings,
  togglePredictionSettings,
} = predictionSettingsSlice.actions

export default predictionSettingsSlice.reducer
