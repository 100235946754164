import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedDatasetID: undefined,
  selectedIterationID: undefined,
}

const datasetsSlice = createSlice({
  name: 'datasets',
  initialState,
  reducers: {
    selectDataset: (state, action) => {
      let { datasetID, iterationID } = action.payload
      if (datasetID) {
        if (!iterationID) {
          iterationID = 'workspace'
        }
        state.selectedDatasetID = datasetID
        state.selectedIterationID = iterationID
      }
      return state
    },
  },
})

export const { selectDataset } = datasetsSlice.actions

export default datasetsSlice.reducer
