import chroma from 'chroma-js'
import _addMilliseconds from 'date-fns/add_milliseconds'
import _distanceInWords from 'date-fns/distance_in_words'
import _distanceInWordsToNow from 'date-fns/distance_in_words_to_now'
import _format from 'date-fns/format'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import KalmanFilter from 'kalmanjs'
import localforage from 'localforage'
import _ from 'lodash'
import pMap from 'p-map'
import Papa from 'papaparse'
import prettyMilliseconds from 'pretty-ms'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useAsync, useDeepCompareEffect, usePrevious } from 'react-use'
import simplify from 'simplify-js'

import appMeta from './appMeta.json'
import {
  database,
  dbEndpoint,
  getDatabase,
  getGCSFileUrl,
  updateTrainingSessionPredictionSettings,
  useDatabase,
  useDatasetWorkspaceAnnotations,
  useDatasetWorkspaceFilenames,
  useTrainingSessionPredictionSettings,
} from './firebase'
import { setNotification } from './redux/notificationSlice'
import {
  initialState as initialPredictionSettings,
  togglePredictionSettings,
  updatePredictionSettings,
} from './redux/predictionSettingsSlice'
import { addPredictionSummary } from './redux/predictionSummariesSlice'
import { selectQueuePrefix } from './redux/queueSlice'
import { selectUserID, selectUserRole } from './redux/userSlice'
import {
  processPredictionResults,
  queueRequest,
  serverRequest,
} from './request'

export const delay = async ms => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}

export const getMediaPath = ({
  datasetID,
  filename,
  inference = false,
  relative = false,
}) => {
  if (inference) datasetID = 'inference'
  return `${
    relative ? '' : 'gs://glow-gu.appspot.com'
  }/ft-annotation-editor/${datasetID}/${filename}`
}

export const getMediaUrl = ({ mediaPath, ext, suffix = '', size } = {}) => {
  if (!mediaPath) return ''
  const re = /(?:(.*)\.([^.]+))?$/
  const [, fileBase, fileExt] = re.exec(mediaPath)
  const appendSize = size ? `@${size}` : ''
  const appendExt = ext ? ext : fileExt
  mediaPath = `${fileBase}${appendSize}${suffix}.${appendExt}`
  const encodedMediaPath = `%2f${encodeURI(mediaPath).replace(/\//g, '%2f')}`
  const firebaseSrc = `https://firebasestorage.googleapis.com/v0/b/glow-gu.appspot.com/o/ft-annotation-editor${encodedMediaPath}?alt=media`
  return firebaseSrc
}

export const useWindowSize = () => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  useEffect(() => {
    const handleResize = () =>
      setSize({ width: window.innerWidth, height: window.innerHeight })
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })
  return size
}

export const useKalmanFilter = initialValue => {
  console.log(`Creating new Kalman Filter ${initialValue}`)
  const kf = new KalmanFilter()
  const [value, setValue] = useState(initialValue)
  const updateValue = newValue => {
    setValue(kf.filter(newValue))
  }

  return [value, updateValue]
}

export const applyKalmanFilter = ({ items, inputKey, outputKey, R, Q }) => {
  let args = {}
  if (R) {
    args.R = R
  }
  if (Q) {
    args.Q = Q
  }

  const kf = new KalmanFilter(args)
  const appliedValues = items.map(item => {
    const inputValue = parseFloat(item[inputKey])
    const appliedValue = !_.isNaN(inputValue) ? kf.filter(inputValue) : null
    return {
      ...item,
      [outputKey]: appliedValue,
    }
  })

  return appliedValues
}

export const removeFilenameExtension = str =>
  str && str.replace(/\.(\w){3,5}$/i, '')

export const getDifficultyColour = ({ difficulty }) => {
  const hue = 90 - 30 * (difficulty - 1)
  const color = difficulty ? `hsl(${hue},70%,60%)` : 'white'
  return color
}

export const getAngleSimple = ({ degrees, arrowOnly = false }) => {
  let text
  if (degrees <= 45 || degrees >= 315) {
    text = 'UP'
  }
  if (degrees >= 45 && degrees <= 135) {
    text = 'RIGHT'
  }
  if (degrees >= 135 && degrees <= 225) {
    text = 'DOWN'
  }
  if (degrees >= 225 && degrees <= 315) {
    text = 'LEFT'
  }
  if (arrowOnly) {
    const replacementText = {
      UP: '↑',
      LEFT: '←',
      RIGHT: '→',
      DOWN: '↓',
    }
    if (text) {
      text = replacementText[text]
    }
  }
  return text
}

export const trackedObjectIsAboveSpeedThreshold = detection => {
  const speedThreshold = 100 // pixels per second
  const speed = detection.spatial_speed
    ? parseFloat(detection.spatial_speed)
    : 0
  return speed > speedThreshold
}

export const parseSplitVideoFilename = (filename = '') => {
  const matches = filename.match(/^(.+)_(\d+)\.(\w{3})$/)
  return (matches || []).length
    ? {
        videoName: matches[1],
        frameNum: parseInt(matches[2], 10),
        ext: matches[3],
      }
    : {}
}

export const formatDate = str => _format(new Date(str), 'DD MMM YYYY')
export const distanceInWords = str =>
  _distanceInWords(new Date(), new Date(str), {
    addSuffix: true,
  })

export const durationInWords = milliseconds =>
  _distanceInWordsToNow(_addMilliseconds(new Date(), milliseconds))

export const humaniseDuration = (ms, ...args) => {
  return prettyMilliseconds(ms || 0, ...args)
}

export const millisecondsToColon = ms => {
  const { hours, minutes, seconds } = parseMilliseconds(ms)
  const padStart = num => _.padStart(num, 2, '0')
  if (hours) {
    return `${hours}:${padStart(minutes)}:${padStart(seconds)}`
  } else {
    return `${padStart(minutes)}:${padStart(seconds)}`
  }
}
export const colonToMilliseconds = string => {
  const re = /((?<hours>[\d]+):)*(?<minutes>[\d]+):(?<seconds>[\d]+)$/
  const { groups } = re.exec(string)
  const { hours, minutes, seconds } = groups
  let sum = 0
  if (hours) {
    sum += parseInt(hours) * 3600000
  }
  if (minutes) {
    sum += parseInt(minutes) * 60000
  }
  if (seconds) {
    sum += parseInt(seconds) * 1000
  }
  return sum
}

export const parseMilliseconds = milliseconds => {
  if (typeof milliseconds !== 'number') {
    throw new TypeError('Expected a number')
  }

  const roundTowardsZero = milliseconds > 0 ? Math.floor : Math.ceil

  return {
    days: roundTowardsZero(milliseconds / 86400000),
    hours: roundTowardsZero(milliseconds / 3600000) % 24,
    minutes: roundTowardsZero(milliseconds / 60000) % 60,
    seconds: roundTowardsZero(milliseconds / 1000) % 60,
    milliseconds: roundTowardsZero(milliseconds) % 1000,
    microseconds: roundTowardsZero(milliseconds * 1000) % 1000,
    nanoseconds: roundTowardsZero(milliseconds * 1e6) % 1000,
  }
}

export const formatTimestamp = ms => {
  if (!_.isNumber(ms)) return null

  const twoDigits = num => _.padStart(num, 2, '0')

  let { hours, minutes, seconds, milliseconds } = parseMilliseconds(ms)
  hours = twoDigits(hours)
  minutes = twoDigits(minutes)
  seconds = twoDigits(seconds)

  let str = ''
  if (parseFloat(hours) > 0) str += `${hours}:`
  str += `${minutes}:${seconds}.${milliseconds}`
  return str
}

export function summariseResults({
  results = [],
  isVideo,
  groupVideoDetections,
}) {
  // Add filename if only frame_num supplied
  results = results.map(det => ({
    ...det,
    filename: det.filename || det.frame_num,
  }))
  const detectionsByVideoName = _.groupBy(results, det =>
    groupVideoDetections
      ? parseSplitVideoFilename(det.filename).videoName
      : det.filename
  )

  let maxN = {
    classNames: {},
    videoNames: {},
  }
  _.each(detectionsByVideoName, (videoDetections, videoName) => {
    const detectionsByFilename = _.groupBy(
      videoDetections,
      isVideo ? 'timestamp' : 'filename'
    )
    _.each(detectionsByFilename, (frameDetections, frame) => {
      const detectionsByClass = _.groupBy(frameDetections, 'class_label')
      _.each(detectionsByClass, (classDetections, class_label) => {
        const n = classDetections.length
        const maxNClass = _.get(maxN, `classNames[${[class_label]}]`, 0)
        const maxNVideoClass = _.get(
          maxN,
          `videoNames['${videoName}'][${class_label}]`,
          0
        )
        if (n > maxNClass) {
          _.set(maxN, `classNames[${[class_label]}]`, n)
        }
        if (n > maxNVideoClass) {
          _.set(maxN, `videoNames['${videoName}'][${class_label}]`, n)
        }
      })
    })
  })

  return {
    maxN,
  }
}

export const returnFileSize = number => {
  let pretty = number
  const MB = number / 1048576
  const KB = number / 1024
  if (number < 1024) {
    pretty = number + 'bytes'
  } else if (number >= 1024 && number < 1048576) {
    pretty = KB.toFixed(1) + 'KB'
  } else if (number >= 1048576) {
    pretty = MB.toFixed(1) + 'MB'
  }

  return {
    pretty,
    number,
    MB,
    KB,
  }
}

export const getClassColours = ({ classNames = [] }) => {
  const classColours = classNames.reduce((acc, className) => {
    const colours = [`hsl(256,100%,57%);`, `hsl(430,100%,57%)`]
    const colScale = chroma.scale(colours).mode('lch')
    const colors = colScale.colors(classNames.length)
    const index = classNames.indexOf(className) % colors.length
    acc[className] = colors[index]
    return acc
  }, {})
  return classColours
}

export const getDatasetColour = ({
  datasetID = '',
  allDatasetIDs = [],
  iterationNumber = 0,
}) => {
  const index = allDatasetIDs.indexOf(datasetID)
  const cols = chroma.brewer.Set2
  const datasetColour = cols[index]
  return datasetColour
}

export const useLocalStorage = (key, initialValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      console.log(error)
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }

  return [storedValue, setValue]
}

export const useForm = (callback, defaultState = {}) => {
  const [values, setValues] = useState({ ...defaultState })

  const handleSubmit = event => {
    if (event) event.preventDefault()
    callback(values)
  }

  const handleChange = useCallback(
    event => {
      event.persist && event.persist()
      const isCheckbox = event.target.type === 'checkbox'
      setValues(values => ({
        ...values,
        [event.target.name]: isCheckbox
          ? event.target.checked
          : event.target.value,
      }))
    },
    [setValues]
  )

  const resetValues = (values = []) => {
    let valuesToSet = _.cloneDeep(defaultState || {})
    if (values.length) {
      _.each(values, value => (valuesToSet[value] = ''))
    }
    setValues(valuesToSet)
  }

  return {
    handleChange,
    handleSubmit,
    values,
    resetValues,
  }
}

export const getDatasetIterationNumber = (datasetIteration = '') => {
  const str = _.toString(datasetIteration)
  const parse = str.match(/([\d]+$)/)
  const match = _.get(parse, '[0]', 0)
  return parseInt(match, 10)
}

export const getAPFromResults = ({
  results = [],
  target = 'AP50',
  targetValueType = 'bbox',
}) => {
  let AP = null
  AP = _.get(
    results.find(res => res.class === target),
    'ap'
  )
  const classResults = _.filter(
    results,
    res => _.isNumber(res.class) && res.type === targetValueType
  )
  const mean = _.mean(_.map(classResults, res => _.get(res, `ap.${target}`)))
  if (AP === -1) {
    AP = mean
  }
  return AP
}

export const getAPFromResultsPerClass = ({
  results = [],
  target = 'AP50',
  targetValueType = 'bbox',
}) => {
  const classResults = _.filter(
    results,
    res => _.isNumber(res.class) && res.type === targetValueType
  ).map(result => ({
    class: result.class,
    value: _.get(result, `ap.${target}`, 0),
    target,
  }))

  return classResults
}

export const calculateTimePerAnnotation = ({
  annotations,
  // if user does not annotate for this duration,
  // it is not counted towards annotation duration
  idleTimeThreshold = 60000,
}) => {
  const timestamps = _.chain(annotations)
    .filter('ts')
    .groupBy('user')
    .toArray()
    // Keep only ts values
    .map(array => array.map(anno => anno.ts).sort())
    .flatten()
    .value()

  // Create groups of temporally adjacent timestamps
  const distances = timestamps
    // Calculate distance in ms
    .map((ts, index) => {
      if (index === 0) return 0
      return ts - timestamps[index - 1]
    })
    // Remove negative distances
    .filter(distance => distance > 0)
    // Remove distances greater than idle time threshold
    .filter(distance => distance < idleTimeThreshold)

  const results = {
    percentageOfTotalAnnotationsUsed: timestamps.length / annotations.length,
    min: _.min(distances),
    max: _.max(distances),
    mean: _.mean(distances),
    total: _.sum(distances),
  }

  return results.mean ? results : null
}

export const convertPredictionSummaryToCsv = ({
  summary,
  key = 'total', // e.g. second, minute, etc
}) => {
  const preparedRows = summary.reduce((rows, value, index) => {
    const { count, tracker_angle, maxN } = value
    const maxNResults = _.mapKeys(maxN, (val, key) => `maxN_${key}`)
    const rowItem = {
      // e.g. second: index
      [key]: index,
      total_detections: count,
      mean_tracker_direction: tracker_angle,
      ...maxNResults, // maxN_YellowfinBream: 10
    }
    rows.push(rowItem)
    return rows
  }, [])

  const rowsSorted = _.sortBy(preparedRows, key)
  const columns = Object.keys(_.merge({}, ...rowsSorted))
  const rowsWithAllColumns = rowsSorted.map(row => {
    // ensure all MaxN values are included, with 0 value if not exist
    const zeros = columns.reduce((acc, key) => {
      acc = { ...acc, [key]: 0 }
      return acc
    }, {})
    return {
      ...zeros,
      ...row,
    }
  })
  const csv = Papa.unparse(rowsWithAllColumns, {
    columns,
    newline: '\n',
  })
  return csv
}

export const preparePredictionSummaryCsvFiles = ({
  predictionSummaries,
  filename,
}) => {
  // for each key in predictionSummaries, convert into CSV string ready for zip
  // {filename, fileContents}
  const allSummaryFiles = Object.entries(predictionSummaries).map(
    ([key, predictionSummary]) => {
      let summary = predictionSummary
      if (!_.isArray(summary)) {
        // perVideo summary is an object
        summary = [predictionSummary]
      }
      const csvString = convertPredictionSummaryToCsv({
        key,
        summary,
      })
      // insert summary key into filename
      const filenameArr = filename.split('summary_')
      filenameArr.splice(1, 0, 'summary_', `${key}_`)
      const filenameReplaced = filenameArr.join('')

      return { filename: filenameReplaced, fileContents: csvString }
    }
  )
  return allSummaryFiles
}

export const downloadCsv = ({ filename, csvString }) => {
  const blob = new Blob([csvString], { type: 'text/plain;charset=utf-8' })
  saveAs(blob, filename)
}

export const getPredictionCsvFilename = ({
  filename,
  trainingSessionID,
  fps,
  imageSize,
  enableTracking,
  processed,
  trackerType,
  workspace,
  ext = 'csv',
  summary = false,
}) => {
  let csvFilename = `FishID_predict_${
    !!summary ? 'summary_' : ''
  }${trainingSessionID}_${filename || workspace}`
  if (!filename || !filename.endsWith('.csv')) {
    csvFilename += `_${fps}fps`
    if (imageSize) {
      csvFilename += `_imageSize${imageSize}`
    }
    if (enableTracking) {
      csvFilename += '-tracking'
      if (trackerType) {
        csvFilename += `-${trackerType}`
      }
    }
    csvFilename += `${processed ? '-processed' : ''}`
    csvFilename += `.${ext}`
  }
  return csvFilename
}

const getStringByteSize = string => {
  if (typeof Buffer === 'function') {
    return Buffer.byteLength(string, 'utf8')
  }
  if (typeof Blob === 'function') {
    return new Blob([string]).size
  }
}

export const simplifyAnnotationSegmentationMasks = ({
  annotations,
  tolerance = 1,
}) => {
  const getByteSize = data => getStringByteSize(JSON.stringify(data))

  const startTime = performance.now()
  const origSize = getByteSize(annotations)

  const segmToObj = segmArray =>
    _.chain(segmArray)
      .chunk(2)
      .map(([x, y]) => ({ x, y }))
      .value()

  const objToSegm = segmObjArray =>
    _.chain(segmObjArray)
      .map(({ x, y }) => [x, y])
      .flatten(2)
      .value()

  const annotationsTransformed = annotations.map(anno => {
    if (anno.isSegmentationSimplified) {
      // don't re-analyse existing simplified segmentation
      return anno
    }
    let segmentation = _.cloneDeep(anno.segmentation)
    if (segmentation && segmentation.length) {
      if (segmentation.length > 1) {
        // Keep the largest polygon at the start of the array
        segmentation = _.sortBy(segmentation, 'length').reverse()
      }
      segmentation = segmentation.map(segm => {
        segm = segmToObj(segm)
        const simplified = simplify(segm, tolerance)
        return objToSegm(simplified)
      })
      return { ...anno, segmentation, isSegmentationSimplified: true }
    } else {
      return anno
    }
  })

  const endTime = performance.now()
  const timeDiff = endTime - startTime
  const newSize = getByteSize(annotationsTransformed)
  const sizeDiff = returnFileSize(origSize - newSize)?.pretty
  console.log(
    `simplifyAnnotationSegmentationMasks: ${timeDiff}ms saving ${sizeDiff}`
  )
  return annotationsTransformed
}

export const useAppVersion = () => appMeta && appMeta.version

export const useServerCurrentJobs = () => {
  const servers = useDatabase({ path: 'servers', asArray: true })
  const [currentJobs, setCurrentJobs] = useState()
  const [currentJobsData, setCurrentJobsData] = useState()
  useEffect(() => {
    const currentJobs = (servers || []).map(server => server.currentJob)
    setCurrentJobs(currentJobs)
    const currentJobsData = (servers || []).map(server => server.currentJobData)
    setCurrentJobsData(currentJobsData)
  }, [servers])
  return { currentJobs, currentJobsData }
}

export const useAreAnyServersConnected = () => {
  const servers = useDatabase({
    path: 'servers',
    asArray: true,
  })
  const [areAnyServersConnected, setAreAnyServersConnected] = useState()

  const serversConnectedCount = useMemo(() => {
    return servers?.filter(
      server => server?.serverConnection?.status === 'connected'
    )?.length
  }, [servers])

  useDeepCompareEffect(() => {
    const areAnyServersConnected = serversConnectedCount
    setAreAnyServersConnected(areAnyServersConnected)
    console.count(`serversConnectedCount`)
  }, [serversConnectedCount])

  return areAnyServersConnected
}

export const usePredictionWorkspaces = ({ datasetID, workspaceKey }) => {
  let path = `predictionWorkspaces/${datasetID}`
  if (workspaceKey) {
    path += `/${workspaceKey}`
  }
  return useDatabase({
    path,
  })
}

export const usePredictionWorkspaceFilenames = ({
  datasetID,
  trainingSessionID,
  activePredictionWorkspace,
}) => {
  const [filteredFilenames, setFilteredFilenames] = useState()

  const predictionWorkspaces = usePredictionWorkspaces({ datasetID })
  const predictionImages = usePredictionImages({ datasetID, trainingSessionID })

  useEffect(() => {
    // Filter Filenames
    if (
      activePredictionWorkspace &&
      predictionWorkspaces?.[activePredictionWorkspace]
    ) {
      const { filenames = [] } = predictionWorkspaces[activePredictionWorkspace]
      // predictionImages = [{key: filename}]
      const filteredFilenames = predictionImages.filter(({ key, filename }) =>
        filenames.includes(filename)
      )
      setFilteredFilenames(filteredFilenames)
    } else {
      setFilteredFilenames(predictionImages)
    }
  }, [activePredictionWorkspace, predictionImages, predictionWorkspaces])

  return filteredFilenames
}

export const usePredictionImages = ({ datasetID, trainingSessionID }) => {
  const [predictionImages, setPredictionImages] = useState([])

  const predictionDataImages = useDatabase({
    path: `predictions/${trainingSessionID}/images`,
  })

  const allPredictionImages = useDatabase({
    path: `predictionImages/${datasetID}`,
  })

  const allPredictionVideoSlices = useDatabase({
    path: `predictionVideoSlices`,
  })

  useEffect(() => {
    const findVideoSlice = filename => {
      if (allPredictionVideoSlices) {
        return Object.values(allPredictionVideoSlices).find(
          data => data.filename === filename
        )
      }
    }

    if (allPredictionImages && allPredictionVideoSlices) {
      const allImages = {
        ...allPredictionImages,
        ..._.get(predictionDataImages, 'images', {}),
      }
      const predictionImages = _.chain(allImages)
        .map((val, key) => {
          const filename = val
          const matchingVideoSlice = findVideoSlice(filename)

          return {
            key,
            filename,
            isSlice: !!matchingVideoSlice,
            ...matchingVideoSlice,
          }
        })
        .uniqBy('filename')
        .sortBy('filename')
        .value()

      setPredictionImages(predictionImages)
    }
  }, [allPredictionImages, predictionDataImages, allPredictionVideoSlices])

  return predictionImages
}

export const isQueueNameValid = queueName =>
  queueName && !queueName.startsWith('undefined')

export const useQueueItems = ({
  queueSuffix,
  user,
  pollInterval = 30000,
  timeout = 600000, // 10mins
  servers, // send through servers object to add current active jobs
  notifyOnTimeout = true,
}) => {
  const queuePrefix = useSelector(selectQueuePrefix)
  const [queueName, setQueueName] = useState()
  const [queueItems, setQueueItems] = useState([])
  const [queueItemsMerged, setQueueItemsMerged] = useState([])
  const [currentServerJobs, setCurrentServerJobs] = useState([])
  const [isValid, setValid] = useState(false)

  const dispatch = useDispatch()
  const pollIntervalID = useRef()
  const startTime = useRef(performance.now())

  const clearPollInterval = () => {
    pollIntervalID.current && clearInterval(pollIntervalID.current)
  }

  const dispatchNotification = useCallback(() => {
    const message = `Queue listener for ${queueSuffix} has timed out. Refresh browser to update queue items.`
    dispatch(
      setNotification({
        message,
        type: 'info',
        loading: false,
      })
    )
  }, [dispatch, queueSuffix])

  const requestItems = useCallback(async () => {
    const timeElapsed = performance.now() - startTime.current

    // clear interval if timeout reached
    if (timeElapsed > timeout) {
      console.log(`Queue timeout reached for listQueueItems/${queueName}`)

      if (notifyOnTimeout) {
        dispatchNotification()
      }

      return clearPollInterval()
    }

    const { items } = await queueRequest({
      action: 'listQueueItems',
      user,
      body: {
        queueName,
      },
    })
    items.forEach(item => (item.queueName = queueName))

    // if is still active
    if (pollIntervalID.current) {
      setQueueItems(prevItems => {
        if (!_.isEqual(prevItems, items)) {
          return items
        } else {
          return prevItems
        }
      })
    }
  }, [dispatchNotification, notifyOnTimeout, queueName, timeout, user])

  useEffect(() => {
    if (queuePrefix && queueSuffix) {
      setQueueName(queuePrefix + '-' + queueSuffix)
    }
  }, [queueSuffix, queuePrefix])

  useEffect(() => {
    // setValid
    const valid = isQueueNameValid(queueName)
    setValid(valid)
    if (queueName && !valid) {
      console.error(`Invalid queueName provided to useQueueItems ${queueName}`)
    }
  }, [queueName])

  useEffect(() => {
    // reset queue items when changing queueName / prefix
    setQueueItems(prevItems => {
      if (!_.isEqual(prevItems, [])) {
        return []
      } else {
        return prevItems
      }
    })

    if (user && isValid) {
      requestItems()
      pollIntervalID.current = setInterval(requestItems, pollInterval)
    }

    return () => {
      clearPollInterval()
    }
  }, [pollInterval, queueName, timeout, user, isValid, requestItems])

  useEffect(() => {
    if (servers && isValid) {
      const serverJobs = getCurrentJobsData({
        servers,
        queueName,
      })
      setCurrentServerJobs(serverJobs)
    }
  }, [servers, queueName, isValid])

  useEffect(() => {
    const newItems = _.uniqBy(
      [...currentServerJobs, ...queueItems],
      'job.jobId'
    )
    if (!_.isEqual(newItems, queueItemsMerged)) {
      setQueueItemsMerged(newItems)
    }
  }, [currentServerJobs, queueItems, queueItemsMerged])

  return {
    queueItems: queueItemsMerged,
    refresh: requestItems,
  }
}

export const removeQueueItem = async ({ user, queueName, messageId }) => {
  // Won't work unless message has been dequeued
  // Consider creating a blacklist in firebase DB to skip queue items
  if (!queueName || !messageId || !user) {
    return console.error('Missing params from removeQueueItem request', {
      user,
      queueName,
      messageId,
    })
  }
  console.log(`Removing ${queueName} item ${messageId}`)
  // const res = await queueRequest({
  //   action: 'deleteQueueItem',
  //   user,
  //   body: {
  //     queueName,
  //     messageId
  //   }
  // })
  // return res
}

export const isJobActive = ({ job, servers }) => {
  const activeJobs = _.map(servers, 'currentJobID')
  return activeJobs.includes(job.jobId)
}

export const getCurrentJobsData = ({ servers = [], queueName }) => {
  const currentServerJobs = _.map(servers, (serverData, serverName) => {
    const { currentJobData, serverConnection } = serverData
    if (currentJobData && serverConnection?.status === 'connected') {
      return { ...currentJobData, serverName }
    }
  })
    .filter(Boolean)
    .filter(item => item.queueName === queueName)
    .map(item => ({ ...item, isActive: true }))
  return currentServerJobs
}

export const stopJob = async ({ jobId, servers = [], user }) => {
  if (!user) {
    console.error('No user provided to stopJob()')
  }
  if (!jobId) {
    console.error('No jobId provided to stopJob()')
  }
  if (!servers?.length) {
    servers = await getDatabase({ path: 'servers', asArray: true })
  }

  const matchingServer = servers.find(server => server.currentJobID === jobId)
  if (
    matchingServer &&
    window.confirm(
      `Are you sure you wish to stop job ${jobId} running on ${matchingServer.name}?`
    )
  ) {
    await serverRequest({
      serverUrl: matchingServer.url,
      action: 'stopCurrentProcess',
      user,
    })
  }
}

export const getOpticalFlowFilename = ({ filename }) =>
  filename && filename.replace('.jpg', '_of.jpg')

export const filterLabelStringCharacters = (str = '') =>
  str.replace(/[^\w\s:;|-]/gi, '')

export const filterFilenameStringCharacters = (str = '') => {
  str = str.replace(/[^\w\d-._]/gi, '_')
  return str
}

export const useGroupedDatasets = ({ userID } = {}) => {
  const datasetGroups = useDatabase({ path: 'datasetGroups', asArray: true })
  const allDatasets = useDatabase({ path: 'datasets', asArray: true })

  const [datasets, setDatasets] = useState([])
  const [isLoaded, setLoaded] = useState(false)
  const [groupedDatasets, setGroupedDatasets] = useState({})
  const [ungroupedDatasets, setUngroupedDatasets] = useState([])
  const [trashedDatasets, setTrashedDatasets] = useState([])

  const currentUserRole = useCurrentUserRole()

  useEffect(() => {
    // setLoaded
    if (datasetGroups && datasets) {
      setLoaded(true)
    }
  }, [datasetGroups, datasets])

  useEffect(() => {
    // setDatasets
    const userIsSuperAdmin = currentUserRole === 'superadmin'
    if (allDatasets?.length) {
      if (!userID || userIsSuperAdmin) {
        setDatasets(allDatasets)
      } else {
        const datasetsWithUserID = allDatasets.filter(({ users = [] }) => {
          return users.includes(userID)
        })
        setDatasets(datasetsWithUserID)
      }
    }
  }, [allDatasets, userID, currentUserRole])

  useEffect(() => {
    // setUngroupedDatasets
    // setTrashedDatasets
    const datasetGroupExists = dataset => {
      if (!dataset.datasetGroupID) {
        return true // dataset group N/A
      }
      return datasetGroups.find(({ key }) => dataset.datasetGroupID === key)
    }
    const isTrashed = dataset => dataset.removed
    const isNotTrashed = dataset => !dataset.removed
    const isUngrouped = dataset =>
      !dataset.datasetGroupID || !datasetGroupExists(dataset)
    const isGrouped = dataset =>
      dataset.datasetGroupID && datasetGroupExists(dataset)

    if (datasetGroups && datasets) {
      const sortedDatasets = _.sortBy(datasets, 'slug')
      const trashedDatasets = sortedDatasets.filter(isTrashed)
      const ungroupedDatasets = sortedDatasets
        .filter(isUngrouped)
        .filter(isNotTrashed)
      const groupedDatasets = _.groupBy(
        sortedDatasets.filter(isNotTrashed).filter(isGrouped),
        'datasetGroupID'
      )
      setGroupedDatasets(groupedDatasets)
      setUngroupedDatasets(ungroupedDatasets)
      setTrashedDatasets(trashedDatasets)
    }
  }, [datasetGroups, datasets])

  return {
    groupedDatasets,
    ungroupedDatasets,
    trashedDatasets,
    datasetGroups,
    datasets,
    isLoaded,
  }
}

export const useDatasets = ({
  showByUserID = false,
  hideRemoved = true,
} = {}) => {
  const datasets = useDatabase({ path: 'datasets', asArray: true })
  const [datasetsFiltered, setDatasetsFiltered] = useState([])

  const currentUserRole = useCurrentUserRole()

  useEffect(() => {
    // filter datasets

    const filterByUserID = dataset => dataset?.users?.includes(showByUserID)
    const filterByRemoved = dataset => !dataset.removed
    const userIsSuperAdmin = currentUserRole === 'superadmin'

    if (datasets) {
      let datasetsFiltered = datasets
      if (hideRemoved) {
        datasetsFiltered = datasetsFiltered.filter(filterByRemoved)
      }
      if (showByUserID !== false && !userIsSuperAdmin) {
        // if current user is not superAdmin
        datasetsFiltered = datasetsFiltered.filter(filterByUserID)
      }

      setDatasetsFiltered(datasetsFiltered)
    }
  }, [datasets, showByUserID, hideRemoved, currentUserRole])

  return { datasets: datasetsFiltered }
}

export const useDatasetImages = ({
  datasetID,
  annotatedImages = [],
  disableAll = false,
}) => {
  const datasetImagesData = useDatabase({
    path: !disableAll && datasetID && `datasetImages/${datasetID}`,
    asArray: true,
  })

  const [datasetImages, setDatasetImages] = useState([])
  const [allImages, setAllImages] = useState([])

  useEffect(() => {
    if (!disableAll && datasetImagesData) {
      const datasetImages = _.uniq(datasetImagesData).filter(Boolean)
      const allImages = _.uniq([...datasetImages, ...annotatedImages]).sort()
      setDatasetImages(datasetImages)
      setAllImages(allImages)
    } else {
      setDatasetImages([])
      setAllImages([])
    }
  }, [datasetImagesData, annotatedImages, disableAll])

  return { datasetImages, allImages }
}

export const useDatasetAnnotations = ({
  datasetID,
  iterationID,
  selectedFilename,
  getAnnotatedFilenames,
  disableAll = false,
}) => {
  const isIteration = useMemo(
    () => iterationID && iterationID !== 'workspace',
    [iterationID]
  )

  // For iterations
  const {
    selectedFilenameAnnotations: iterationSelectedFilenameAnnotations,
    filenames: iterationAnnotatedFilenameMeta,
    labels: iterationLabels,
    isLoading: cocoJsonLoading,
    hasSegmentationsMasks,
  } = useDatasetIteration({
    enabled: !disableAll && datasetID && isIteration,
    datasetID,
    datasetIteration: iterationID,
    filename: selectedFilename,
  })

  // For workspace
  const workspaceSelectedFilenameAnnotations = useDatasetWorkspaceAnnotations({
    enabled: !disableAll && selectedFilename && !isIteration,
    datasetID,
    filename: selectedFilename,
  })
  const {
    filenames: workspaceAnnotatedFilenameMeta,
    labels: workspaceLabels,
  } = useDatasetWorkspaceFilenames({
    enabled: !disableAll && getAnnotatedFilenames && !isIteration,
    datasetID,
  })

  // Select either workspace or iteration results
  const {
    annotatedFilenameMeta,
    labels,
    selectedFilenameAnnotations,
    isLoading,
  } = useMemo(() => {
    if (isIteration) {
      return {
        annotatedFilenameMeta: iterationAnnotatedFilenameMeta,
        selectedFilenameAnnotations: iterationSelectedFilenameAnnotations,
        labels: iterationLabels,
        isLoading: cocoJsonLoading,
      }
    } else {
      return {
        annotatedFilenameMeta: workspaceAnnotatedFilenameMeta,
        selectedFilenameAnnotations: workspaceSelectedFilenameAnnotations,
        labels: workspaceLabels,
        isLoading: false,
      }
    }
  }, [
    isIteration,
    iterationAnnotatedFilenameMeta,
    iterationLabels,
    iterationSelectedFilenameAnnotations,
    workspaceAnnotatedFilenameMeta,
    workspaceLabels,
    workspaceSelectedFilenameAnnotations,
    cocoJsonLoading,
  ])

  // Calculate annotationsCount
  const annotationsCount = useMemo(
    () =>
      _.sumBy(annotatedFilenameMeta, ({ counts = {} }) =>
        _.sum(Object.values(counts))
      ),
    [annotatedFilenameMeta]
  )

  // Create annotatedFilenames array of strings for convenience
  const annotatedFilenames = useMemo(
    () =>
      _.chain(annotatedFilenameMeta)
        .filter(meta => meta.counts)
        .map('filename')
        .value(),
    [annotatedFilenameMeta]
  )

  const editedFilenames = useMemo(() => {
    const isEdited = filenameMeta => filenameMeta.edited
    const editedFilenameObjs = annotatedFilenameMeta.filter(isEdited)
    return editedFilenameObjs.map(filenameMeta => filenameMeta.filename)
  }, [annotatedFilenameMeta])

  return {
    annotatedFilenameMeta,
    annotatedFilenames,
    annotationsCount,
    labels,
    selectedFilenameAnnotations,
    isLoading,
    hasSegmentationsMasks,
    editedFilenames,
  }
}

export const useDatasetWorkspaceSuggestedAnnotations = ({
  datasetID,
  selectedFilename,
  disableAll = false,
}) => {
  const selectedFilenameSuggestedAnnotations = useDatasetWorkspaceAnnotations({
    enabled: !disableAll && selectedFilename && datasetID,
    datasetID,
    filename: selectedFilename,
    suggested: true,
  })

  const {
    filenames: suggestedAnnotationFilenameCounts,
    labels: suggestedLabels,
  } = useDatasetWorkspaceFilenames({
    enabled: !disableAll && !selectedFilename,
    datasetID,
    suggested: true,
  })

  // Create annotatedFilenames array of strings for convenience
  const suggestedAnnotatedFilenames = useMemo(
    () => _.map(suggestedAnnotationFilenameCounts, 'filename'),
    [suggestedAnnotationFilenameCounts]
  )

  return {
    suggestedAnnotationFilenameCounts,
    suggestedAnnotatedFilenames,
    suggestedLabels,
    selectedFilenameSuggestedAnnotations,
  }
}

const fetchDatasetIteration = async ({ datasetID, datasetIteration }) => {
  if (!datasetID || !datasetIteration || datasetIteration === 'workspace') {
    return console.log(`fetchDatasetIteration missing args`, {
      datasetID,
      datasetIteration,
    })
  }

  const filename = `${datasetID}_${datasetIteration}.json`

  const localStoragePath = `/datasetIterations/${filename}`
  const filePath = `ft-dataset-iterations/${datasetID}/${filename}`

  let cocoJson = await localforage.getItem(localStoragePath)
  if (!cocoJson) {
    console.log(`🛰 Dataset iteration not cached, fetching remote ${filePath}`)
    const url = await getGCSFileUrl({ filePath })
    cocoJson = await fetch(url)
      .then(data => data.json())
      .catch(e => console.error(e))
    if (cocoJson) {
      await localforage.setItem(localStoragePath, cocoJson)
    }
  } else {
    console.log(
      `📦 Using dataset iteration from cache ${datasetID} ${datasetIteration}`
    )
  }
  return cocoJson
}

const convertXYWHtoXXYY = ([x, y, w, h]) => {
  const xmin = x
  const ymin = y
  const xmax = x + w
  const ymax = y + h
  return [xmin, xmax, ymin, ymax]
}

export const cocoJsonToDatasetAnnotations = ({ cocoJson }) => {
  let annotations = []
  let filenames = []
  let labels = {}

  if (cocoJson) {
    annotations = cocoJson.annotations.map(anno => {
      const matchingImage = cocoJson.images.find(
        image => image.id === anno.image_id
      )
      const matchingCat = cocoJson.categories.find(
        cat => cat.id === anno.category_id
      )

      const [x, y, width, height] = anno.bbox
      const [xmin, xmax, ymin, ymax] = convertXYWHtoXXYY([x, y, width, height])

      const id = anno.originalId || anno.id

      let segmentation = anno.segmentation
      try {
        if (_.isString(anno.segmentation)) {
          segmentation = JSON.parse(anno.segmentation)
        }
      } catch (e) {
        // pass
      }

      return {
        ...anno,
        id,
        class: matchingCat.name,
        filename: matchingImage.file_name,
        width: matchingImage.width,
        height: matchingImage.height,
        xmin,
        xmax,
        ymin,
        ymax,
        segmentation,
      }
    })
    const { annotatedFilenames } = calculateAnnotatedFilenames({ annotations })
    labels = getLabelsFromAnnotatedFilenames({
      annotatedFilenames,
    })
    filenames = annotatedFilenames
  }

  return {
    filenames,
    labels,
    annotations,
  }
}

const calculateAnnotatedFilenames = ({ annotations }) => {
  const annotationsByFilename = _.groupBy(annotations, 'filename')
  const annotatedFilenames = _.reduce(
    annotationsByFilename,
    (acc, filenameAnnos, filename) => {
      // [{
      //   [filename]: '',
      // counts: {
      //   [className]: 1
      // }
      // }]
      const meta = { filename }
      filenameAnnos.forEach(anno => {
        const className = anno.class
        const existingCount = _.get(meta, `counts.${className}`, 0)
        // increment class
        _.set(meta, `counts.${className}`, existingCount + 1)
      })
      acc.push(meta)
      return acc
    },
    []
  )
  return { annotatedFilenames }
}

export const getLabelsFromAnnotatedFilenames = ({ annotatedFilenames }) => {
  return annotatedFilenames.reduce((acc, { counts: labelCounts }) => {
    _.each(labelCounts, (count, labelName) => {
      const existingCount = _.get(acc, `${labelName}`, 0)
      _.set(acc, labelName, existingCount + count)
    })
    return acc
  }, {})
}

export const useDatasetIteration = ({
  datasetID,
  datasetIteration,
  filename,
  enabled,
}) => {
  const { loading: cocoJsonLoading, value: cocoJson } = useAsync(async () => {
    if (enabled && datasetID && datasetIteration) {
      let cocoJson = {}
      cocoJson = await fetchDatasetIteration({
        datasetID,
        datasetIteration,
      })
      return cocoJson
    } else {
      return null
    }
  }, [datasetID, datasetIteration, enabled])

  const { filenames, labels, annotations } = useMemo(() => {
    if (cocoJsonLoading) {
      return { filenames: [], labels: {}, annotations: [] }
    } else {
      const { filenames, labels, annotations } = cocoJsonToDatasetAnnotations({
        cocoJson,
      })
      return { filenames, labels, annotations }
    }
  }, [cocoJson, cocoJsonLoading])

  const selectedFilenameAnnotations = useMemo(() => {
    return annotations.filter(anno => anno.filename === filename)
  }, [annotations, filename])

  const hasSegmentationsMasks = useMemo(() => {
    return _.some(annotations, anno => anno.segmentation?.length >= 1)
  }, [annotations])

  return {
    cocoJson,
    selectedFilenameAnnotations,
    filenames,
    labels,
    isLoading: cocoJsonLoading,
    annotations,
    hasSegmentationsMasks,
  }
}

export const getDatasetIteration = async ({ datasetID, datasetIteration }) => {
  const cocoJson = await fetchDatasetIteration({
    datasetID,
    datasetIteration,
  })

  const { info, images, categories } = cocoJson

  const { filenames, labels, annotations } = cocoJsonToDatasetAnnotations({
    cocoJson,
  })

  const hasSegmentationsMasks = _.some(
    annotations,
    anno => anno.segmentation?.length >= 1
  )

  return {
    cocoJson,
    filenames,
    labels,
    annotations,
    hasSegmentationsMasks,
    info,
    images,
    categories,
  }
}

export async function getDatasetIterationAnnotations({
  datasetID,
  datasetIteration,
  filterByClassList = [],
  strictClassFilter,
}) {
  let { annotations } = await getDatasetIteration({
    datasetID,
    datasetIteration,
  })
  if (filterByClassList && filterByClassList.length) {
    if (strictClassFilter) {
      annotations = filterAnnotations({
        annotations,
        keepOnlyClassList: filterByClassList,
      })
    } else {
      annotations = annotations.filter(anno =>
        filterByClassList.includes(anno.class)
      )
    }
  }
  return annotations
}

const filterAnnotations = ({ annotations = [], keepOnlyClassList = [] }) => {
  let annotationsFiltered = annotations.filter((anno, index) => {
    if (!anno) return false
    const width = parseInt(anno.width, 10)
    const height = parseInt(anno.height, 10)
    if (!anno.class || !anno.filename || !width || !height) {
      console.log(`Annotation ${index} missing data, skipping`)
      return false
    } else {
      return true
    }
  })

  if (keepOnlyClassList && keepOnlyClassList.length) {
    // Filter out filenames that have species not in keepOnlyClassList
    const unfilteredFilenamesLength = _.chain(annotationsFiltered)
      .filter(anno => keepOnlyClassList.includes(anno.class))
      .map('filename')
      .uniq()
      .value().length
    const annosByFilename = _.groupBy(annotationsFiltered, 'filename')
    const filenamesWithOnlyClassList = _.reduce(
      annosByFilename,
      (result, filenameAnnos, filename) => {
        // if any annotations are a class not in classList, reject
        const hasClassesNotInClassList = _.some(
          filenameAnnos,
          anno => !keepOnlyClassList.includes(anno.class)
        )
        if (!hasClassesNotInClassList) {
          result.push(filename)
        }
        return result
      },
      []
    )
    annotationsFiltered = annotationsFiltered.filter(anno =>
      filenamesWithOnlyClassList.includes(anno.filename)
    )
    const filteredFilenamesLength = _.chain(annotationsFiltered)
      .map('filename')
      .uniq()
      .value().length
    console.log(
      `Filtering annotated filenames using keepOnlyClassList – ${keepOnlyClassList.length} classes – ${filteredFilenamesLength}/${unfilteredFilenamesLength} filenames`
    )
  }
  return annotationsFiltered
}

export const useServers = ({ hideDisabled = true, filterByGroup } = {}) => {
  const [serversFiltered, setServersFiltered] = useState([])
  const servers = useDatabase({
    path: 'servers',
    asArray: true,
  })

  useEffect(() => {
    if (servers) {
      let serversFiltered = servers
      if (hideDisabled) {
        serversFiltered = serversFiltered.filter(
          server => server?.disabled !== true
        )
      }
      if (filterByGroup) {
        const isDev = server => server?.name?.toLowerCase()?.includes('dev')
        if (filterByGroup?.toLowerCase() === 'dev') {
          serversFiltered = serversFiltered.filter(server => isDev(server))
        } else {
          serversFiltered = serversFiltered.filter(
            server =>
              server?.serverResourceGroup === filterByGroup && !isDev(server)
          )
        }
      }
      setServersFiltered(serversFiltered)
    }
  }, [hideDisabled, filterByGroup, servers])

  return serversFiltered
}

export const useCurrentUserRole = () => {
  const currentUserRole = useSelector(selectUserRole)
  return currentUserRole
}

export const useUserIsAdmin = () => {
  const currentUserRole = useSelector(selectUserRole)
  const isAdmin =
    currentUserRole === 'admin' || currentUserRole === 'superadmin'
  return isAdmin
}

export const useUserID = () => {
  const userID = useSelector(selectUserID)
  return userID
}

export const getDefaultCheckpoint = async ({
  trainingSessionID,
  datasetID,
}) => {
  const defaultCheckpoint = await getDatabase({
    path: `/trainingSessions/${datasetID}/${trainingSessionID}/defaultCheckpoint`,
  })
  return defaultCheckpoint
}

export const checkFramesAreSequential = frames => {
  const frameNumbers = frames
    .sort()
    .map(filename => parseSplitVideoFilename(filename)?.frameNum)
  const areNumbersSequential = checkNumbersAreSequential(frameNumbers)
  return areNumbersSequential
}

export const checkFramesAreFromSingleVideo = frames => {
  const frameVideos = frames.map(
    filename => parseSplitVideoFilename(filename)?.videoName
  )
  const videosSet = new Set(frameVideos)
  return videosSet.size === 1
}

const checkNumbersAreSequential = numbers => {
  const numbersSubtracted = numbers.map((currentNum, index) => {
    const prevNum = index > 0 ? numbers[index - 1] : currentNum - 1
    const diff = currentNum - prevNum
    return diff
  })
  const uniqDiffs = [...new Set(numbersSubtracted)]
  const numbersAreSequential = uniqDiffs.length === 1
  return numbersAreSequential
}

export const compareDatasetAnnotations = (annotationsA, annotationsB) => {
  // Compare annotations by ID
  const annotationIdDiff = _.xorBy(annotationsA, annotationsB, 'id')
  const editedAnnotations = _.uniqBy(annotationIdDiff, 'id')
  const editedAnnotationsFilenames = editedAnnotations.map(
    anno => anno.filename
  )

  // compare annotation filenames
  let editedFilenames = _.xor(
    _.uniq(annotationsA.map(anno => anno.filename)),
    _.uniq(annotationsB.map(anno => anno.filename))
  )

  // Add edited annotation filenames to editedFilename array
  editedFilenames = _.uniq([...editedAnnotationsFilenames, ...editedFilenames])

  return { editedFilenames, editedAnnotations }
}

const mapAnnotations = annotations => {
  console.log(`mapAnnotations: ${annotations.length}`)
  const isTrackerAnno = anno =>
    (anno.is_tracker || 'false').toString().toLowerCase() === 'true'

  return annotations.map(anno => {
    let updatedAnno = {
      ...anno,
      is_tracker: isTrackerAnno(anno),
      filename: anno.frame_num,
      category_id: parseInt(anno.class_number, 10),
      score: _.round(parseFloat(anno.detection_score), 2),
      detection_score: _.round(parseFloat(anno.detection_score), 2),
      tracker_score: _.round(parseFloat(anno.tracker_score), 2),
      tracker_center_x: _.round(parseFloat(anno.tracker_center_x), 5),
      tracker_center_y: _.round(parseFloat(anno.tracker_center_y), 5),
      xmin: _.round(parseFloat(anno.xmin || anno.tracker_xmin), 0),
      ymin: _.round(parseFloat(anno.ymin || anno.tracker_ymin), 0),
      xmax: _.round(parseFloat(anno.xmax || anno.tracker_xmax), 0),
      ymax: _.round(parseFloat(anno.ymax || anno.tracker_ymax), 0),
      tracker_angle: _.round(parseFloat(anno.tracker_angle), 0),
    }
    if (anno.timestamp) {
      updatedAnno.timestamp = parseInt(anno.timestamp)
    } else if (anno.frame_num) {
      updatedAnno.frame_index = parseInt(
        anno.frame_num.match(/_\d+fps_(\d+)\./)[1]
      )
    }

    return updatedAnno
  })
}

const processAnnotationTrackerTrails = ({ annotations }) => {
  let updatedAnnotations = [...annotations]
  const groupedByTrackerID = _.groupBy(updatedAnnotations, 'tracker_id')

  Object.entries(groupedByTrackerID).forEach(([trackerID, annos]) => {
    if (parseInt(trackerID) >= 0) {
      annos = _.sortBy(annos, 'timestamp')
      annos.forEach((anno, index) => {
        // ignore first detection
        if (index > 0) {
          const prevDet = annos[index - 1]
          if (prevDet) {
            // Add history of center_smoothed coords to trail
            const trail = annos
              .slice(0, index + 1)
              .map(det => [det.tracker_center_x, det.tracker_center_y])
            anno['tracker_trail'] = trail
          }
        }
      })
      groupedByTrackerID[trackerID] = annos
    }
  })
  updatedAnnotations = [].concat(...Object.values(groupedByTrackerID))
  console.log({ updatedAnnotations })
  return updatedAnnotations
}

export const usePredictionSettings = () => {
  const dispatch = useDispatch()

  const updateStoredSettings = payload =>
    dispatch(updatePredictionSettings(payload))
  const toggleStoredSettings = payload =>
    dispatch(togglePredictionSettings(payload))

  const storedSettings = useSelector(state => state.predictionSettings)

  return { storedSettings, updateStoredSettings, toggleStoredSettings }
}

export const useModelPredictionSettings = ({
  trainingSessionID,
  datasetID,
} = {}) => {
  const storedSettings = useTrainingSessionPredictionSettings({
    trainingSessionID,
    datasetID,
  })

  useEffect(() => {
    const initiateEmpty = async () => {
      const isValid = !!datasetID && !!trainingSessionID
      const existingData = await getDatabase({
        path:
          isValid &&
          `trainingSessions/${datasetID}/${trainingSessionID}/predictionSettings`,
      })
      if (!existingData) {
        // inititate new settings by default
        await updateTrainingSessionPredictionSettings({
          datasetID,
          trainingSessionID,
          updates: initialPredictionSettings,
        })
      }
    }
    initiateEmpty()
  }, [datasetID, trainingSessionID])

  const updateStoredSettings = useCallback(
    async updates => {
      await updateTrainingSessionPredictionSettings({
        datasetID,
        trainingSessionID,
        updates,
      })
    },
    [datasetID, trainingSessionID]
  )

  const toggleStoredSettings = useCallback(
    async settingName => {
      const prevValue = storedSettings[settingName]
      const updates = {
        [settingName]: !prevValue,
      }
      await updateStoredSettings({ updates })
    },
    [storedSettings, updateStoredSettings]
  )

  return { storedSettings, updateStoredSettings, toggleStoredSettings }
}

export const downloadZippedFiles = async ({
  files,
  archiveFilename = 'archive.zip',
}) => {
  // files = [{filename = '', fileContents = ''}]
  const zip = new JSZip()

  files.forEach(({ filename, fileContents }) => {
    zip.file(filename, fileContents)
  })

  const zipFile = await zip.generateAsync({ type: 'blob' })
  return saveAs(zipFile, archiveFilename)
}

export const preparePredictionCsvData = ({
  processedResults,
  predictedFilename,
  predictionSettings,
  includeSegmentationMasks = true,
}) => {
  let results = _.chain(processedResults)
    .map(row => ({
      ...row,
      filename: predictedFilename,
      center_x: row.center && row.center[0],
      center_y: row.center && row.center[1],
      center_smoothed_x: row.center && row.center_smoothed[0],
      center_smoothed_y: row.center && row.center_smoothed[1],
      ...predictionSettings,
    }))
    .map(row => {
      if (includeSegmentationMasks) {
        if (row?.segmentation) {
          row.segmentation = JSON.stringify(row.segmentation)
        }
        return row
      } else {
        return _.omit(row, 'segmentation')
      }
    })
    .map(row => _.omitBy(row, _.isNaN))
    .map(row => _.omitBy(row, _.isNull))
    .map(row => _.omitBy(row, _.isArray))
    .map(row => _.omitBy(row, _.isUndefined))
    .value()

  const columns = results.reduce((columns, row) => {
    const rowKeys = Object.keys(row)
    columns = _.uniq([...columns, ...rowKeys]).sort()
    return columns
  }, [])

  results = results
    .map(row => {
      // add missing columns (keys)
      columns.forEach(colName => {
        if (_.isNil(row[colName])) {
          row[colName] = null
        }
      })
      return row
    })
    .map(row =>
      // sort csv columns
      _.chain(row).toPairs().sortBy(0).fromPairs().value()
    )

  return Papa.unparse(results, { columns })
}

export const detectValidationPeak = ({
  processedCheckpointResults,
  targetValueType = 'bbox',
}) => {
  const targetValue = `${targetValueType}.AP50`

  if (!processedCheckpointResults) {
    return
  }

  const toObject = ([checkpoint, results]) => ({ checkpoint, results })

  const getTargetValue = ({ checkpoint, results }) => {
    const value = _.get(results, `overall.${targetValue}`)
    return { checkpoint, value }
  }

  const calculateForwardAverage = ({ checkpoint, value }, index) => {
    const allValues = values.map(({ checkpoint, value }) => value)
    let forwardAverage = 0

    const window = allValues.slice(index, allValues.length)
    forwardAverage = _.mean(window)

    const isPeak = value > forwardAverage

    return { checkpoint, value, forwardAverage, isPeak }
  }

  const calculateCheckpointWeight = result => {
    // prefer earlier checkpoints
    const increasedCheckpointPenalty = 0.2
    const maxCheckpoint = 100000
    const checkpointInt = parseInt(result.checkpoint, 10)
    const progress = checkpointInt / maxCheckpoint
    let checkpointWeight = 1 - progress * increasedCheckpointPenalty
    if (checkpointWeight < 0.1) {
      checkpointWeight = 0.1
    }

    const weightedValue = result.value * checkpointWeight
    return { ...result, checkpointWeight, weightedValue }
  }

  let values = Object.entries(processedCheckpointResults)
    .map(toObject)
    .map(getTargetValue)

  values = values.map(calculateForwardAverage).map(calculateCheckpointWeight)

  const potentialPeaks = values.filter(value => value.isPeak === true)
  const highestPotentialPeak = _.sortBy(
    potentialPeaks,
    'weightedValue'
  ).reverse()[0]

  return highestPotentialPeak
}

export const processCheckpointResults = ({ checkpointResults }) => {
  if (!checkpointResults) {
    return
  }

  const checkpointResultsArr = Object.entries(checkpointResults)
  let processedResults = {}

  const mapResults = (acc, result) => {
    const addCumulativeApValues = ([apResultKey, apValue]) => {
      const existingValue = _.get(
        acc,
        `overall.summary.${result.type}.${apResultKey}`,
        []
      )
      _.set(acc, `overall.summary.${result.type}.${apResultKey}`, [
        ...existingValue,
        apValue,
      ])
    }

    // if result.ap is object, results are category/class, else results are overall
    if (_.isObject(result.ap)) {
      // Add all results to class.type (e.g. className.bbox)
      _.set(acc, `${result.class}.${result.type}`, { ...result.ap })
      // add apResult to an cumulative array of values
      Object.entries(result.ap).forEach(addCumulativeApValues)
    } else {
      _.set(acc, `overall.${result.type}.${result.class}`, result.ap)
    }
    return acc
  }

  const mapCheckpoints = ([checkpoint, data]) => {
    const { results } = data
    const processedCheckpointResults = results.reduce(mapResults, {})
    return [checkpoint, processedCheckpointResults]
  }

  const calculateOverallValues = ([checkpoint, results]) => {
    const {
      overall: { summary, ...overall },
    } = results

    Object.entries(overall).forEach(([type, apResults]) => {
      Object.entries(apResults).forEach(([apKey, apValue]) => {
        // If no overall apValue, replace with mean of individial category results (summary)
        if (apValue === -1) {
          const cumulativeApResults = _.get(summary, `${type}.${apKey}`)
          const mean = _.mean(cumulativeApResults)
          // overwrite overall results with mean
          _.set(results, `overall.${type}.${apKey}`, mean)
        }
      })
    })
    return [checkpoint, results]
  }

  // Map results to an
  processedResults = checkpointResultsArr.map(mapCheckpoints)

  // if overall is showing -1 values, use mean of each category/class
  processedResults = processedResults.map(calculateOverallValues)

  // convert back to object
  processedResults = processedResults.reduce((acc, [checkpoint, results]) => {
    acc[checkpoint] = results
    return acc
  }, {})

  return processedResults
}

export const useAllUsers = ({ currentUserID }) => {
  const allUsersData = useDatabase({ path: '/users' })
  const [allUsers, setAllUsers] = useState()

  const getUserColor = ({ index, light = false }) => {
    const palette = chroma.brewer.Set2
    let color = palette[index % palette.length]
    if (light) {
      color = chroma(color).set('hsl.l', 0.85)
    }
    return color
  }

  useEffect(() => {
    if (allUsersData) {
      const userIDs = Object.keys(allUsersData)
      let allUsers = userIDs.reduce((acc, userID, index) => {
        const userObject = allUsersData[userID]
        acc[userID] = {
          ...userObject,
          uid: userID,
          color: getUserColor({ index: index + 1 }),
          colorLight: getUserColor({ index: index + 1, light: true }),
        }
        if (currentUserID) {
          acc[userID].isCurrentUser = currentUserID === userID
        }
        return acc
      }, {})

      setAllUsers(allUsers)
    }
  }, [allUsersData, currentUserID])

  return allUsers
}

export const getPredictionSliceDetails = ({ filename }) => {
  const sliceData = getDatabase({
    path: `predictionVideoSlices/${_.snakeCase(filename)}`,
  })
  return sliceData
}
export const getPredictionSliceJobId = ({ trainingSessionID, filename }) => {
  // get firebase generated key for new prediction job
  const path = `${dbEndpoint}/predictions/${trainingSessionID}/results/${_.snakeCase(
    filename
  )}`
  const newKey = database.ref(path).push().key
  console.log(newKey)
  return newKey
}

const getNearestPredictionTimestampGroup = ({ currentTimestamp }) => {
  const timestampBuffer = 5000 // ms
  const nearestTimestamp =
    Math.floor(currentTimestamp / timestampBuffer) * timestampBuffer
  return nearestTimestamp
}

const getPredictionAnnotations = async ({
  trainingSessionID,
  filenameKey,
  predictionID,
  configID,
  currentTimestamp = 0,
}) => {
  const nearestTimestamp = getNearestPredictionTimestampGroup({
    currentTimestamp,
  })
  const path = `/predictions/${trainingSessionID}/annotations/${filenameKey}/${predictionID}/${configID}/${nearestTimestamp}`

  console.time(`Retrieving local prediction annotations\n${path}\n`)
  let data = await localforage.getItem(path)
  console.timeEnd(`Retrieving local prediction annotations\n${path}\n`)

  if (!data) {
    console.time(`Retrieving remote prediction annotations\n${path}\n`)
    data = await getDatabase({ path })
    console.timeEnd(`Retrieving remote prediction annotations\n${path}\n`)
    if (data) {
      await localforage.setItem(path, data)
    }
  }

  if (data) {
    let dataArr = Papa.parse(data, {
      header: true,
      skipEmptyLines: true,
    }).data
    if (dataArr?.length) {
      // post-processing
      dataArr = dataArr.map(parseSegmentationMask)
    }

    return { [nearestTimestamp]: dataArr }
  }
}

const clearPredictionAnnotationsCache = async ({
  trainingSessionID,
  filenameKey,
  predictionID,
  configID,
}) => {
  const path = `/predictions/${trainingSessionID}/annotations/${filenameKey}/${predictionID}/${configID}`

  // remove all localforage items with matching key, e.g. all cached timestamp group annotations
  console.time(`Clearing local prediction annotations cache\n${path}\n`)
  const cacheKeys = await localforage.keys()
  const matchingKeys = cacheKeys.filter(key => key.includes(path))
  console.log(`Removing ${matchingKeys.length} items from predictions cache`)
  const removeCache = key => localforage.removeItem(key)
  await pMap(matchingKeys, removeCache, { concurrency: 50 })
  console.timeEnd(`Clearing local prediction annotations cache\n${path}\n`)
}

const parseSegmentationMask = anno => {
  if (anno.segmentation && typeof anno.segmentation === 'string') {
    const segmentation = JSON.parse(anno.segmentation)
    return {
      ...anno,
      segmentation,
    }
  } else {
    return anno
  }
}

export const usePredictionAnnotations = ({
  trainingSessionID,
  filename,
  predictionID,
  currentTimestamp,
  maxTimestamp,
}) => {
  const fetchInProgress = useRef(false)
  const [seqLinkLength, setSeqLinkLength] = useState()
  const [
    predictionAnnotationsGrouped,
    setPredictionAnnotationsGrouped,
  ] = useState({
    // [timestampGroup]: [{...anno}, ...]
  })
  const [predictionAnnotations, setPredictionAnnotations] = useState([
    // {...anno}, ...
  ])
  const {
    confidenceThreshold,
    useSeqNMS,
    minLinkLength,
    trackerStaleCount,
    trackerMaxSustain,
    inputConfidenceFilter,
  } = useSelector(state => state.predictionSettings)

  const configID = useMemo(() => {
    const allParamsValid = () => {
      if (!_.isNumber(confidenceThreshold)) return false
      if (!_.isNumber(trackerStaleCount)) return false
      if (!_.isNumber(seqLinkLength)) return false
      if (!filename) return false
      return true
    }
    if (allParamsValid()) {
      const configID = getPredictionConfigID({
        filename,
        confidenceThreshold,
        seqLinkLength,
        trackerStaleCount,
        trackerMaxSustain,
        inputConfidenceFilter,
      })
      return configID
    } else {
      return null
    }
  }, [
    confidenceThreshold,
    trackerStaleCount,
    seqLinkLength,
    filename,
    trackerMaxSustain,
    inputConfidenceFilter,
  ])

  const filenameKey = useMemo(() => {
    if (filename) {
      const filenameKey = _.snakeCase(filename)
      return filenameKey
    } else {
      return null
    }
  }, [filename])

  const processIncomingAnnotations = ({
    groupedAnnos,
    currentTimestamp = 0,
  }) => {
    const getNearestTimestampGroup = anno => {
      const timestampGroupSize = 5000
      // e.g. 10200 (timestamp) / 5000 (groupSize) = 2.04
      // floor(2.04) = 2
      // 2 * 5000 (groupSize) = 10000
      const nearestTimestampGroup =
        Math.floor(anno.timestamp / timestampGroupSize) * timestampGroupSize
      return nearestTimestampGroup
    }
    if (groupedAnnos) {
      // only process timestamp groups within 10000ms of currentTimestamp
      const timestampIsWithinRange = timestamp =>
        Math.abs(parseInt(timestamp) - currentTimestamp) < 10000
      const groupsToProcess = _.pickBy(groupedAnnos, (annos, timestampGroup) =>
        timestampIsWithinRange(timestampGroup)
      )
      const consoleMessage = `Processing grouped annotations [${Object.keys(
        groupsToProcess
      ).join(', ')}]`
      console.time(consoleMessage)
      // get all annotations array
      let annotations = _.flatten(Object.values(groupsToProcess))
      // map annotations (rounding etc)
      annotations = mapAnnotations(annotations)
      // processing
      annotations = processAnnotationTrackerTrails({
        annotations,
      })
      // re-group by timestamp
      const annotationsGroupedByTimestamp = _.groupBy(
        annotations,
        getNearestTimestampGroup
      )
      console.timeEnd(consoleMessage)
      return {
        ...groupedAnnos,
        ...annotationsGroupedByTimestamp,
      }
    } else {
      return groupedAnnos
    }
  }

  const getAnnosAndUpdateState = useCallback(
    async ({
      trainingSessionID,
      filenameKey,
      predictionID,
      configID,
      currentTimestamp,
      nearestTimestamp,
      isLookahead = false,
    }) => {
      console.info(`getAnnosAndUpdateState`)
      if (
        currentTimestamp >= 0 &&
        trainingSessionID &&
        filenameKey &&
        predictionID &&
        configID
      ) {
        setPredictionAnnotationsGrouped(prevAnnos => {
          // add empty results to prevent multiple calls
          if (!prevAnnos?.[nearestTimestamp]) {
            return {
              ...prevAnnos,
              [nearestTimestamp]: [],
            }
          } else {
            return prevAnnos
          }
        })

        fetchInProgress.current = true
        let groupedAnnos = await getPredictionAnnotations({
          trainingSessionID,
          filenameKey,
          predictionID,
          configID,
          currentTimestamp,
        })

        if (groupedAnnos) {
          setPredictionAnnotationsGrouped(prevAnnos => {
            let updatedAnnos = {
              ...prevAnnos,
              ...groupedAnnos,
            }
            updatedAnnos = processIncomingAnnotations({
              groupedAnnos: updatedAnnos,
              currentTimestamp,
            })

            return updatedAnnos
          })
          if (!isLookahead) {
            setPredictionAnnotations(groupedAnnos[nearestTimestamp])
          }
        }

        // max fetch every 1000ms
        await delay(1000)

        fetchInProgress.current = false
      }
    },
    []
  )

  useEffect(() => {
    // reset data when changing config/trainingSessionID etc
    setPredictionAnnotationsGrouped({})
    setPredictionAnnotations([])
  }, [
    configID,
    trainingSessionID,
    filename,
    predictionID,
    confidenceThreshold,
    useSeqNMS,
    minLinkLength,
    trackerStaleCount,
  ])

  useEffect(() => {
    const lookAhead = 5000
    const nearestTimestamp = getNearestPredictionTimestampGroup({
      currentTimestamp,
    })

    const getAnnosAsync = async () => {
      if (predictionAnnotationsGrouped?.[nearestTimestamp]?.length) {
        setPredictionAnnotations(predictionAnnotationsGrouped[nearestTimestamp])
      } else if (!fetchInProgress.current) {
        await getAnnosAndUpdateState({
          trainingSessionID,
          filenameKey,
          predictionID,
          configID,
          currentTimestamp,
          nearestTimestamp,
        })
      }

      const lookAheadNearestTimestamp = getNearestPredictionTimestampGroup({
        currentTimestamp: currentTimestamp + lookAhead,
      })

      if (
        lookAheadNearestTimestamp < maxTimestamp &&
        !predictionAnnotationsGrouped?.[lookAheadNearestTimestamp]?.length &&
        !fetchInProgress.current
      ) {
        await getAnnosAndUpdateState({
          trainingSessionID,
          filenameKey,
          predictionID,
          configID,
          currentTimestamp: currentTimestamp + lookAhead,
          nearestTimestamp: lookAheadNearestTimestamp,
          isLookahead: true,
        })
      }
    }

    getAnnosAsync()
  }, [
    configID,
    currentTimestamp,
    filenameKey,
    predictionID,
    trainingSessionID,
    predictionAnnotationsGrouped,
    fetchInProgress,
    getAnnosAndUpdateState,
    maxTimestamp,
  ])

  useEffect(() => {
    let seqLinkLength = useSeqNMS ? minLinkLength : 0
    setSeqLinkLength(seqLinkLength)
  }, [minLinkLength, useSeqNMS])

  return { predictionAnnotations, predictionAnnotationsGrouped }
}

const fetchPredictionData = async ({
  trainingSessionID,
  filenameKey,
  predictionID,
  configID,
  type, // 'summaries'
}) => {
  const path = `/predictions/${trainingSessionID}/${type}/${filenameKey}/${predictionID}/${configID}`
  const data = await getDatabase({ path })
  return data
}

export const usePredictionSummaries = ({
  user,
  trainingSessionID,
  filename,
  predictionID,
  onError,
}) => {
  const [configID, setConfigID] = useState()
  const [filenameKey, setFilenameKey] = useState()
  const [seqLinkLength, setSeqLinkLength] = useState()
  const stateKey = useMemo(() => {
    if (configID && trainingSessionID && filenameKey && predictionID) {
      const stateKey = `${trainingSessionID}/${filenameKey}/${predictionID}/${configID}`
      return stateKey
    } else {
      return null
    }
  }, [configID, trainingSessionID, filenameKey, predictionID])
  const prevStateKey = usePrevious(stateKey)

  const [fetchesInProgress, setFetchesInProgress] = useState([])
  const [fetchesComplete, setFetchesComplete] = useState([])

  const [predictionSummariesLoaded, setPredictionSummariesLoaded] = useState(
    false
  )
  const predictionSummaries = useSelector(
    state => state.predictionSummaries?.[stateKey]
  )

  const store = useStore()
  const dispatch = useDispatch()
  const {
    confidenceThreshold,
    useSeqNMS,
    minLinkLength,
    trackerStaleCount,
    trackerMaxSustain,
    inputConfidenceFilter,
  } = useSelector(state => state.predictionSettings)

  const fetchAndStore = useCallback(
    async ({ trainingSessionID, filenameKey, predictionID, configID }) => {
      await clearPredictionAnnotationsCache({
        filenameKey,
        trainingSessionID,
        predictionID,
        configID,
      })

      const summaryData = await fetchPredictionData({
        trainingSessionID,
        filenameKey,
        predictionID,
        configID,
        type: 'summaries',
      })

      if (!summaryData) {
        return false
      }
      // save to local store
      dispatch(
        addPredictionSummary({
          data: summaryData,
          trainingSessionID,
          filenameKey,
          predictionID,
          configID,
        })
      )
      return true
    },
    [dispatch]
  )

  const handleDataUpdateRequest = useCallback(
    async ({ stateKey, forceRefresh = false } = {}) => {
      // check local store for summaries
      const predictionSummaries = store.getState()?.predictionSummaries?.[
        stateKey
      ]

      if (predictionSummaries && !forceRefresh) {
        return console.info(`predictionData exists`)
      }
      if (fetchesComplete.includes(stateKey) && !forceRefresh) {
        return console.info(`predictionData fetch has already completed`, {
          fetchesComplete,
        })
      }
      if (fetchesInProgress.includes(stateKey)) {
        return console.info(`predictionData fetch already in progress`, {
          fetchesInProgress,
        })
      }

      console.info(`predictionData doesn't exist locally, requesting data`)
      setFetchesInProgress(prevData => _.uniq([...prevData, stateKey]))

      const summaryDataExists = forceRefresh
        ? false
        : await fetchAndStore({
            trainingSessionID,
            filenameKey,
            predictionID,
            configID,
          })

      if (!summaryDataExists) {
        // request summary
        console.info(`predictionSummary has not been processed, requesting`)
        try {
          const { success } = await processPredictionResults({
            user,
            trainingSessionID,
            filename,
            predictionID,
            inputConfidenceFilter,
            confidenceThreshold,
            seqLinkLength,
            trackerStaleCount,
            trackerMaxSustain,
          })
          if (success) {
            await delay(500)
            await fetchAndStore({
              trainingSessionID,
              filenameKey,
              predictionID,
              configID,
            })
          } else {
            console.error(`processPredictionResults did not return success`)
          }
        } catch (e) {
          console.error(e)
          if (onError) {
            onError(e)
          }
        }
      }
      setFetchesInProgress(prevData => _.without(prevData, stateKey))
      setFetchesComplete(prevData => _.uniq([...prevData, stateKey]))
    },
    [
      store,
      fetchesComplete,
      fetchesInProgress,
      fetchAndStore,
      trainingSessionID,
      filenameKey,
      predictionID,
      configID,
      user,
      filename,
      inputConfidenceFilter,
      confidenceThreshold,
      seqLinkLength,
      trackerStaleCount,
      trackerMaxSustain,
      onError,
    ]
  )

  useEffect(() => {
    let seqLinkLength = useSeqNMS ? minLinkLength : 0
    setSeqLinkLength(seqLinkLength)
  }, [minLinkLength, useSeqNMS])

  useEffect(() => {
    if (stateKey && stateKey !== prevStateKey) {
      handleDataUpdateRequest({ stateKey })
    }
  }, [handleDataUpdateRequest, stateKey, prevStateKey])

  useEffect(() => {
    const allParamsValid = () => {
      if (!_.isNumber(confidenceThreshold)) return false
      if (!_.isNumber(trackerStaleCount)) return false
      if (!_.isNumber(seqLinkLength)) return false
      if (!filename) return false
      return true
    }
    if (allParamsValid()) {
      const configID = getPredictionConfigID({
        filename,
        confidenceThreshold,
        seqLinkLength,
        trackerStaleCount,
        trackerMaxSustain,
        inputConfidenceFilter,
      })
      setConfigID(configID)
      const filenameKey = _.snakeCase(filename)
      setFilenameKey(filenameKey)
    } else {
      setConfigID()
      setFilenameKey()
    }
  }, [
    confidenceThreshold,
    useSeqNMS,
    seqLinkLength,
    trackerStaleCount,
    trackerMaxSustain,
    filename,
    inputConfidenceFilter,
  ])

  const forcePredictionSummariesRefresh = useCallback(async () => {
    if (stateKey) {
      await handleDataUpdateRequest({
        forceRefresh: true,
        stateKey,
      }).catch(console.error)
    }
  }, [handleDataUpdateRequest, stateKey])

  useEffect(() => {
    setPredictionSummariesLoaded(!fetchesInProgress.length)
  }, [fetchesInProgress])

  return {
    predictionSummaries,
    predictionSummariesLoaded,
    forcePredictionSummariesRefresh,
  }
}

const getPredictionConfigID = ({
  confidenceThreshold,
  inputConfidenceFilter,
  seqLinkLength,
  trackerStaleCount,
  trackerMaxSustain,
}) => {
  const ctKey = parseInt(confidenceThreshold * 100)
  let configID = `ct-${ctKey}`
  configID += `_seqnms-${seqLinkLength}`
  configID += `_stalecount-${trackerStaleCount}`
  if (trackerMaxSustain && seqLinkLength > 0) {
    configID += `_trackermaxsustain-${trackerMaxSustain}`
  }
  if (inputConfidenceFilter === true && seqLinkLength > 0) {
    configID += `_inputConfidenceFilter`
  }
  return configID
}

export const roundTo = (number, precision = 0.05) => {
  const divideBy = 1 / precision
  const numberRounded = Math.round(number * divideBy) / divideBy.toFixed(2)
  return numberRounded
}

export const getImageProcessingOptions = () => {
  const imageProcessingOptions = [
    {
      label: 'CLAHE (Contrast Limited Adaptive Histogram Equalisation)',
      value: 'clahe',
    },
    {
      label: 'Background Subtraction (KNN)',
      value: 'backgroundSubtractionKNN',
    },
  ]
  return imageProcessingOptions
}

export const getTrainingSessionStorageOptions = () => {
  const trainingSessionStorageOptions = [
    {
      label: 'Full',
      value: 0,
      message: 'Keeps all model files',
      color: 'gray',
      canPredict: true,
      canEval: true,
    },
    {
      label: 'Slim',
      value: 1,
      message: 'Only keep the selected default checkpoint',
      color: 'orange',
      canPredict: true,
      canEval: true,
    },
    {
      label: 'Results Only',
      value: 2,
      message: 'Delete all model files, but keep all existing results',
      color: '#ff3d3d',
      canPredict: false,
      canEval: false,
    },
  ]
  return trainingSessionStorageOptions
}

export const getTrainingSessionStorageOption = ({ storageCategory }) => {
  const trainingSessionStorageOptions = getTrainingSessionStorageOptions()
  if (!storageCategory) storageCategory = 0
  const option = trainingSessionStorageOptions.find(
    ({ value }) => value === storageCategory
  )
  return option
}

export const getConfigFilenames = () => {
  return [
    {
      name: 'FasterRCNN Resnet50',
      filename: 'e2e_faster_rcnn_R_50_FPN_1x.yaml',
    },
    {
      name: 'FasterRCNN Resnet101',
      filename: 'e2e_faster_rcnn_R_101_FPN_1x.yaml',
    },
    { name: 'MaskRCNN Resnet50', filename: 'e2e_mask_rcnn_R_50_FPN_1x.yaml' },
    { name: 'MaskRCNN Resnet101', filename: 'e2e_mask_rcnn_R_101_FPN_1x.yaml' },
  ]
}

export const getPrettyConfigName = ({ configFilename }) => {
  const configFilenames = getConfigFilenames()
  const configName = configFilenames.find(
    ({ filename }) => filename === configFilename
  )?.name
  return configName
}

export const exportDatasetIterationCSV = async ({
  datasetID,
  datasetIteration,
  datasetSlug,
}) => {
  const { annotations } = await getDatasetIteration({
    datasetID,
    datasetIteration,
  })

  const filename = `${datasetSlug}_${datasetIteration}.csv`

  const csv = Papa.unparse(annotations)
  const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' })
  saveAs(blob, filename)
}

export const exportDatasetIterationCocoJson = async ({
  datasetID,
  datasetIteration,
  datasetSlug,
}) => {
  const { cocoJson } = await getDatasetIteration({
    datasetID,
    datasetIteration,
  })

  const filename = `${datasetSlug}_${datasetIteration}.json`
  const cocoJsonString = JSON.stringify(cocoJson, null, 2)

  const blob = new Blob([cocoJsonString], {
    type: 'text/plain;charset=utf-8',
  })
  saveAs(blob, filename)
}
