import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'

import { saveAs } from 'file-saver'
import firebase from 'firebase/app'
import _ from 'lodash'
import pMap from 'p-map'
import { randanimal } from 'randanimal'
import Rebase from 're-base'
import { useEffect, useState } from 'react'
import { useDeepCompareEffect } from 'react-use'
import shortid from 'shortid'

import { getLabelsFromAnnotatedFilenames } from './utils'

const serverSettings = {
  configFilenames: [
    // 'e2e_faster_rcnn_R_50_C4_1x.yaml',
    'e2e_faster_rcnn_R_50_FPN_1x.yaml',
    'e2e_faster_rcnn_R_101_FPN_1x.yaml',
    // 'e2e_faster_rcnn_X_101_32x8d_FPN_1x.yaml',
    // 'e2e_mask_rcnn_R_50_C4_1x.yaml',
    'e2e_mask_rcnn_R_50_FPN_1x.yaml',
    'e2e_mask_rcnn_R_101_FPN_1x.yaml',
    // 'e2e_mask_rcnn_X_101_32x8d_FPN_1x.yaml'
  ],
}

const config = {
  apiKey: 'AIzaSyC5WHAuBsuZVsgcaFTJ8fjeoOVtE6XWnow',
  authDomain: 'glow-gu.firebaseapp.com',
  databaseURL: 'https://glow-gu.firebaseio.com',
  projectId: 'glow-gu',
  storageBucket: 'glow-gu.appspot.com',
  messagingSenderId: '236483791443',
}

export const dbEndpoint = 'ft-annotation-editor'

const app = firebase.initializeApp(config)
export const database = app.database()
const firestore = firebase.firestore()

const storage = firebase.storage()
export const storageRef = storage.ref()
export const auth = firebase.auth()

const dbRef = database.ref('ft-annotation-editor')

const connectedRef = database.ref('.info/connected')
const serverTimeOffsetRef = database.ref('/.info/serverTimeOffset')
export const handleFirebaseDisconnect = (callback = () => {}) => {
  connectedRef.on('value', snap => {
    return callback(snap.val())
  })
}

let serverTimeOffset = null
export const base = Rebase.createClass(database)

export function useDatabase({ path, asArray = false, omitKeys, pickKeys }) {
  const [state, setState] = useState(undefined)

  useDeepCompareEffect(() => {
    const handleValueChange = async snapshot => {
      let data = await snapshot.val()
      if (data && asArray && !Array.isArray(data)) {
        // Convert data values to array
        const reduceDataToArray = (arr, [key, value]) => {
          let item = value

          // add [key] property if value is an object
          if (_.isObject(value)) {
            item = {
              key,
              ...value,
            }
          }

          arr.push(item)
          return arr
        }

        if (_.isObject(data)) {
          data = Object.entries(data).reduce(reduceDataToArray, [])
        } else {
          data = []
        }
      }

      if (omitKeys?.length) {
        data = _.omit(data, omitKeys)
      }
      if (pickKeys?.length) {
        data = _.pick(data, pickKeys)
      }

      setState(prevState => {
        if (!_.isEqual(data, prevState)) {
          return data
        } else {
          return prevState
        }
      })
    }

    if (path) {
      const databaseRef = database.ref(`${dbEndpoint}/${path}`)
      console.log(`✨ Listening to ${dbEndpoint}/${path}`)
      databaseRef.on('value', handleValueChange)

      return () => {
        console.log(`✨ Removing binding ${dbEndpoint}/${path}`)
        databaseRef.off('value', handleValueChange)
      }
    }
  }, [path, asArray, omitKeys, pickKeys])

  return state
}

export async function getDatabase({ path, asArray = false }) {
  if (path) {
    console.log(`✨ Fetching ${dbEndpoint}/${path}`)
    console.time(`✨ Fetching ${dbEndpoint}/${path}`)
    const databaseRef = database.ref(`${dbEndpoint}/${path}`)
    return databaseRef
      .once('value')
      .then(snapshot => snapshot.val())
      .then(data => {
        if (data && asArray && !Array.isArray(data)) {
          // Convert data values to array
          data = Object.values(data)
        }
        console.timeEnd(`Fetching ${dbEndpoint}/${path}`)
        return data
      })
  }
}

export async function getDatasetWorkspaceAnnotations({
  datasetID,
  filename,
  suggested = false,
}) {
  const mainCollection = suggested
    ? 'datasetWorkspaceSuggestedAnnotations'
    : 'datasetWorkspaces'
  const annotationsRef = firestore
    .collection(mainCollection)
    .doc(datasetID)
    .collection('annotations')

  let query = annotationsRef

  if (filename) {
    query = annotationsRef.where('filename', '==', filename)
  }

  const docs = await query.get().then(snapshot => {
    const results = []
    snapshot.forEach(doc => results.push(doc.data()))
    return results
  })

  return docs
}

const mapFirestoreAnnotations = annotations => {
  const mappedAnnotations = annotations.map(anno => {
    if (anno.segmentation) {
      anno.segmentation = JSON.parse(anno.segmentation)
    }
    return anno
  })
  return mappedAnnotations
}

export const useTrainingSessionConfig = ({ datasetID, trainingSessionID }) => {
  const isValid = !!datasetID && !!trainingSessionID
  return useDatabase({
    path: isValid && `trainingSessions/${datasetID}/${trainingSessionID}`,
    omitKeys: [`evaluations`, `logTime`],
  })
}

export const useTrainingSessionEvaluations = ({
  datasetID,
  trainingSessionID,
}) => {
  const isValid = !!datasetID && !!trainingSessionID
  return useDatabase({
    path:
      isValid &&
      `trainingSessions/${datasetID}/${trainingSessionID}/evaluations`,
    omitKeys: [`evaluations`, `logTime`],
  })
}

export const useEvaluationConfig = ({
  datasetID,
  trainingSessionID,
  evaluationID,
}) => {
  const isValid = !!datasetID && !!trainingSessionID && !!evaluationID
  return useDatabase({
    path:
      isValid &&
      `trainingSessions/${datasetID}/${trainingSessionID}/evaluations/${evaluationID}`,
  })
}

export const useDatasetSlug = ({ datasetID }) => {
  return useDatabase({
    path: !!datasetID && `datasets/${datasetID}/slug`,
  })
}

export const useDataset = ({ datasetID }) => {
  return useDatabase({
    path: !!datasetID && `datasets/${datasetID}`,
  })
}

export const useDatasetWorkspaceAnnotations = ({
  datasetID,
  filename,
  suggested = false,
  enabled = false,
}) => {
  const [annotations, setAnnotations] = useState([])

  useEffect(() => {
    let unsubscribe

    let pathString = `datasetWorkspaces/${datasetID}/annotations/${filename}`
    let mainCollection = `datasetWorkspaces`
    if (suggested) {
      pathString = `datasetWorkspaceSuggestedAnnotations/${datasetID}/annotations/${filename}`
      mainCollection = `datasetWorkspaceSuggestedAnnotations`
    }
    if (enabled && datasetID && filename) {
      setAnnotations([])
      const annotationsRef = firestore
        .collection(mainCollection)
        .doc(datasetID)
        .collection('annotations')

      let query = annotationsRef

      if (filename) {
        query = annotationsRef.where('filename', '==', filename)
      }

      console.log(`🔥 Firestore listener attached: ${pathString}`)
      unsubscribe = query.onSnapshot(snapshot => {
        let annos = []
        snapshot.forEach(doc => {
          const source = doc.metadata.hasPendingWrites ? 'local 💻' : 'server 🛰'
          // console.log(`annotation update source ${source}`)
          annos.push(doc.data())
        })
        annos = mapFirestoreAnnotations(annos)
        setAnnotations(annos)
      })
    } else {
      setAnnotations([])
    }
    return () => {
      if (unsubscribe) {
        console.log(`🔥 Firestore listener detached: ${pathString}`)
        unsubscribe()
      }
    }
  }, [datasetID, filename, enabled, suggested])

  return annotations
}

export const useDatasetWorkspaceFilenames = ({
  enabled = true,
  datasetID,
  suggested = false,
}) => {
  const [filenames, setFilenames] = useState([])
  const [labels, setLabels] = useState({})

  useEffect(() => {
    let unsubscribe

    let pathString = `datasetWorkspaces/${datasetID}/filenames`
    let mainCollection = `datasetWorkspaces`
    if (suggested) {
      pathString = `datasetWorkspaceSuggestedAnnotations/${datasetID}/filenames`
      mainCollection = `datasetWorkspaceSuggestedAnnotations`
    }
    if (enabled && datasetID) {
      setFilenames([])
      const filenamesRef = firestore
        .collection(mainCollection)
        .doc(datasetID)
        .collection('filenames')

      console.log(`🔥 Firestore listener attached: ${pathString}`)
      unsubscribe = filenamesRef.onSnapshot(snapshot => {
        let filenames = []
        snapshot.forEach(doc => {
          const docData = doc.data()
          filenames.push({
            filename: doc.id,
            counts: docData.counts,
            edited: docData.edited,
          })
        })
        setFilenames(filenames)
      })
    } else {
      setFilenames([])
    }
    return () => {
      if (unsubscribe) {
        console.log(`🔥 Firestore listener detached: ${pathString}`)
        unsubscribe()
      }
    }
  }, [datasetID, enabled, suggested])

  useEffect(() => {
    // setLabels
    if (filenames?.length) {
      const labels = getLabelsFromAnnotatedFilenames({
        annotatedFilenames: filenames,
      })

      setLabels(labels)
    } else {
      setLabels({})
    }
  }, [filenames])

  return { filenames, labels }
}

export const getDatasetWorkspaceFilenames = async ({ datasetID }) => {
  let pathString = `datasetWorkspaces/${datasetID}/filenames`

  const filenamesRef = firestore
    .collection('datasetWorkspaces')
    .doc(datasetID)
    .collection('filenames')

  console.log(`🔥 Fetching firestore: ${pathString}`)

  const filenames = await filenamesRef.get().then(snapshot => {
    let filenames = []
    snapshot.forEach(doc => {
      const filenameMeta = doc.data()
      filenames.push({
        filename: doc.id,
        ...filenameMeta,
      })
    })
    return filenames
  })

  const labels = filenames?.length
    ? getLabelsFromAnnotatedFilenames({
        annotatedFilenames: filenames,
      })
    : {}

  return { filenames, labels }
}

export async function getTrainingSessionConfig({
  datasetID,
  trainingSessionID,
}) {
  const trainingSessionConfig = await getDatabase({
    path: `trainingSessions/${datasetID}/${trainingSessionID}`,
  })
  return trainingSessionConfig
}

export const generateID = () => shortid.generate()

export const generateName = async () => randanimal()

export const getTimestamp = () => base.timestamp

export const getTimestampMs = async () => {
  const now = new Date()
  if (_.isNull(serverTimeOffset)) {
    serverTimeOffsetRef.once('value').then(offset => {
      const offsetVal = offset.val() || 0
      serverTimeOffset = offsetVal
      const serverTime = now.getTime() + serverTimeOffset
      return serverTime
    })
  } else {
    const serverTime = now.getTime() + serverTimeOffset
    return serverTime
  }
}

export const setStorageItemCache = ({ mediaPath }) => {
  const mediaRef = storageRef.child(`${dbEndpoint}/${mediaPath}`)
  var newMetadata = {
    cacheControl: 'public,max-age=2628000',
  }
  return mediaRef
    .updateMetadata(newMetadata)
    .then(metadata => {
      console.log('Updated metadata')
    })
    .catch(error => {
      console.warn(error)
    })
}

export const getGCSFileUrl = async ({ filePath }) => {
  // check session storage for previously requested paths
  let url = sessionStorage.getItem(filePath)
  if (!url) {
    console.log(filePath)
    const fileRef = storageRef.child(filePath)
    url = await fileRef.getDownloadURL().catch(e => console.error(e))
    if (url) {
      sessionStorage.setItem(filePath, url)
    }
  }
  return url
}

export const downloadGCSFile = async ({ filePath }) => {
  // check session storage for previously requested paths
  const url = await getGCSFileUrl({ filePath })
  const filename = filePath.split('/').pop()
  return saveAs(url, filename)
}

export const uploadImage = ({
  filename,
  file,
  datasetID,
  inferenceID,
  inferenceDatasetID,
  callback = () => {},
}) =>
  new Promise((resolve, reject) => {
    if (!filename || !file) {
      console.warn('Cannot uploadImage', { filename, file })
      return reject({ filename, file })
    }

    const path = datasetID
      ? `ft-annotation-editor/${datasetID}/${filename}`
      : `ft-annotation-editor/inference/${filename}`
    const metadata = {
      cacheControl: 'public,max-age=2628000',
    }
    const imageRef = storageRef.child(path)

    const uploadTask = imageRef.put(file, metadata)
    // Pause the upload
    // uploadTask.pause();

    // // Resume the upload
    // uploadTask.resume();

    // // Cancel the upload
    // uploadTask.cancel();

    uploadTask.on(
      'state_changed',
      snapshot => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        callback({ snapshot })

        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused')
            break
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running')
            break
          default:
            break
        }
      },
      function (error) {
        callback({ error })
        reject({ error })
        // Handle unsuccessful uploads
      },
      function () {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        console.log(`ℹ Uploaded ${path}`)

        if (datasetID) {
          addImageReference({ datasetID, filename })
        }
        if (inferenceID) {
          addImageReference({ inferenceID, filename })
        }
        if (inferenceDatasetID) {
          addImageReference({ inferenceDatasetID, filename })
        }
        resolve({ ref: uploadTask.snapshot.ref.toString(), path })
      }
    )
  })

export const uploadVideo = ({
  filename,
  file,
  datasetID,
  inferenceDatasetID,
  customMetadata = {},
  callback = () => {},
}) =>
  new Promise((resolve, reject) => {
    if (!filename || !file || (!datasetID && !inferenceDatasetID)) {
      console.warn('Cannot uploadVideo', {
        filename,
        file,
        datasetID,
        inferenceDatasetID,
      })
      return reject({ filename, file, datasetID, inferenceDatasetID })
    }

    let path = null
    if (datasetID) {
      path = `ft-annotation-editor/${datasetID}-videos/${filename}`
    } else if (inferenceDatasetID) {
      path = `ft-annotation-editor/inference/${filename}`
    }
    const metadata = {
      cacheControl: 'public,max-age=2628000',
      customMetadata,
    }

    const videoRef = storageRef.child(path)

    const uploadTask = videoRef.put(file, metadata)
    // Pause the upload
    // uploadTask.pause();

    // // Resume the upload
    // uploadTask.resume();

    // // Cancel the upload
    // uploadTask.cancel();

    uploadTask.on(
      'state_changed',
      snapshot => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        callback({ snapshot })

        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused')
            break
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running')
            break
          default:
            break
        }
      },
      function (error) {
        callback({ error })
        reject({ error })
        // Handle unsuccessful uploads
      },
      function () {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        console.log(`ℹ Uploaded ${path}`)
        resolve({ ref: uploadTask.snapshot.ref.toString(), path })

        if (inferenceDatasetID) {
          addImageReference({ inferenceDatasetID, filename })
        }
      }
    )
  })

export const addImageReference = ({
  datasetID,
  filename,
  inferenceID,
  inferenceDatasetID,
}) => {
  if (!filename || (!datasetID && !inferenceID && !inferenceDatasetID))
    return console.warn('Cannot addImageReference', {
      datasetID,
      inferenceID,
      filename,
    })

  let path
  if (datasetID) {
    path = `${dbEndpoint}/datasetImages/${datasetID}`
  } else if (inferenceID) {
    path = `${dbEndpoint}/predictions/${inferenceID}/images`
  } else if (inferenceDatasetID) {
    path = `${dbEndpoint}/predictionImages/${inferenceDatasetID}`
  }

  return base
    .push(path, {
      data: filename,
    })
    .then(console.log)
    .catch(error => {
      console.warn(error)
    })
}

export const removeImageReference = async ({
  datasetID,
  datasetImageKey,
  inferenceDatasetID,
  modelID,
}) => {
  if (!datasetImageKey || (!datasetID && !modelID && !inferenceDatasetID))
    return console.error('Cannot removeImageReference', {
      datasetID,
      datasetImageKey,
      modelID,
      inferenceDatasetID,
    })

  let path
  if (datasetID) {
    path = `${dbEndpoint}/datasetImages/${datasetID}/${datasetImageKey}`
  } else if (modelID) {
    path = `${dbEndpoint}/predictions/${modelID}/images/${datasetImageKey}`
  }

  if (inferenceDatasetID) {
    // Also remove from predictionImages/${inferenceDatasetID}
    const predictionImagesPath = `${dbEndpoint}/predictionImages/${inferenceDatasetID}/${datasetImageKey}`
    console.log(`Removing image reference: ${predictionImagesPath}`)
    await base.remove(predictionImagesPath).catch(error => {
      console.warn(error)
    })
  }

  console.log(`Removing image reference: ${path}`)

  return base.remove(path).catch(error => {
    console.warn(error)
  })
}

export const createNewDataset = ({ userID, datasetGroupID }) => {
  if (!userID) return console.error('No user supplied to createNewDataset')

  const datasetName = window.prompt(
    'What would you like to name the new dataset?'
  )
  if (!datasetName) return false

  const datasetSlug = _.kebabCase(datasetName)

  const datasetID = generateID()

  const dataset = {
    images: [],
    slug: datasetSlug,
    users: [userID],
  }

  // ft-annotation-editor/datasets/${datasetID} = dataset

  return base
    .update(`${dbEndpoint}/datasets/${datasetID}`, { data: dataset })
    .then(() => {
      if (datasetGroupID) {
        return addDatasetToGroup({ datasetID, datasetGroupID })
      }
    })
}

export function updateTrainingSessionConfig({
  datasetID,
  trainingSessionID,
  updates,
}) {
  return new Promise((resolve, reject) => {
    const trainingSessionDatabasePath = `trainingSessions/${datasetID}/${trainingSessionID}`
    console.log('Updating firebase', trainingSessionDatabasePath)
    if (!datasetID || !trainingSessionID) {
      reject(new Error('Missing params for updateTrainingSessionConfig'))
    }
    if (_.isEmpty(updates)) {
      reject(new Error('No updates provided to updateTrainingSessionConfig'))
    }
    // Remove evaluations key if present
    updates = _.omit(updates, ['evaluations'])
    dbRef.child(trainingSessionDatabasePath).update(updates, () => {
      resolve(updates)
    })
  })
}

export const useTrainingSessionPredictionSettings = ({
  datasetID,
  trainingSessionID,
}) => {
  const isValid = !!datasetID && !!trainingSessionID
  return useDatabase({
    path:
      isValid &&
      `trainingSessions/${datasetID}/${trainingSessionID}/predictionSettings`,
  })
}

export function updateTrainingSessionPredictionSettings({
  datasetID,
  trainingSessionID,
  updates,
}) {
  return new Promise((resolve, reject) => {
    const trainingSessionPredictionSettingsDatabasePath = `trainingSessions/${datasetID}/${trainingSessionID}/predictionSettings`
    console.log(
      'Updating firebase',
      trainingSessionPredictionSettingsDatabasePath
    )
    if (!datasetID || !trainingSessionID) {
      reject(
        new Error('Missing params for updateTrainingSessionPredictionSettings')
      )
    }
    if (_.isEmpty(updates)) {
      reject(
        new Error(
          'No updates provided to updateTrainingSessionPredictionSettings'
        )
      )
    }
    dbRef
      .child(trainingSessionPredictionSettingsDatabasePath)
      .update(updates, () => {
        resolve(updates)
      })
  })
}

export async function updateTrainingSessionEvaluation({
  datasetID,
  trainingSessionID,
  evaluationID,
  updates,
}) {
  if (!datasetID || !trainingSessionID || !evaluationID) {
    throw new Error('Missing params updateTrainingSessionConfig')
  }
  const trainingSessionEvaluationDatabasePath = `trainingSessions/${datasetID}/${trainingSessionID}/evaluations/${evaluationID}`
  console.log('Updating firebase', trainingSessionEvaluationDatabasePath)
  if (_.isEmpty(updates)) {
    throw new Error('No updates provided to updateTrainingSessionConfig')
  }
  await dbRef.child(trainingSessionEvaluationDatabasePath).update(updates)
  const evaluationConfig = await getDatabase({
    path: trainingSessionEvaluationDatabasePath,
  })
  return evaluationConfig
}

export async function removeTrainingSession({ trainingSessionID, datasetID }) {
  if (!datasetID || !trainingSessionID) {
    throw new Error(`Missing parameter ${datasetID} ${trainingSessionID}`)
  }

  const trainingSessionConfig = await getTrainingSessionConfig({
    trainingSessionID,
    datasetID,
  })

  if (!trainingSessionConfig) {
    throw new Error(
      `Couldn't get trainingSessionConfig: ${datasetID} ${trainingSessionID}`
    )
  }

  const trainingSessionDatabaseStatus = 'Removed'
  const trainingSessionDatabaseRemoved = true

  await updateTrainingSessionConfig({
    datasetID,
    trainingSessionID,
    updates: {
      status: trainingSessionDatabaseStatus,
      removed: trainingSessionDatabaseRemoved,
    },
  })

  return {
    ...trainingSessionConfig,
    status: trainingSessionDatabaseStatus,
    removed: trainingSessionDatabaseRemoved,
  }
}

export async function removeTrainingSessionEvaluation({
  datasetID,
  trainingSessionID,
  evaluationID,
}) {
  if (!datasetID || !trainingSessionID || !evaluationID) {
    throw new Error(
      `Missing parameter ${datasetID} ${trainingSessionID} ${evaluationID}`
    )
  }
  const evaluationConfig = await updateTrainingSessionEvaluation({
    datasetID,
    trainingSessionID,
    evaluationID,
    updates: {
      status: 'Removed',
    },
  })

  return evaluationConfig
}

export function updateDataset({ datasetID, updates }) {
  return new Promise((resolve, reject) => {
    const datasetDatabasePath = `datasets/${datasetID}`
    console.log('Updating firebase', datasetDatabasePath)
    if (!datasetID) {
      reject(new Error('Missing params for updateDataset'))
    }
    if (_.isEmpty(updates)) {
      reject(new Error('No updates provided to updateDataset'))
    }
    // Keep only certain keys
    updates = _.pick(updates, ['fps', 'slug', 'removed', 'datasetGroupID'])
    dbRef.child(datasetDatabasePath).update(updates, resolve)
  })
}

export function createPredictionWorkspace({ datasetID, name }) {
  return new Promise((resolve, reject) => {
    const datasetDatabasePath = `predictionWorkspaces/${datasetID}`
    console.log('Updating firebase', datasetDatabasePath)
    if (!datasetID || !_.isString(name)) {
      return reject(new Error('Missing params for createPredictionWorkspace'))
    }
    dbRef.child(datasetDatabasePath).push({ name: name.trim() }, resolve)
  })
}

export function updatePredictionWorkspace({ datasetID, key, updates }) {
  return new Promise((resolve, reject) => {
    const datasetDatabasePath = `predictionWorkspaces/${datasetID}/${key}`
    console.log('Updating firebase', datasetDatabasePath)
    if (!datasetID || !key) {
      reject(new Error('Missing params for updatePredictionWorkspace'))
    }
    if (_.isEmpty(updates)) {
      reject(new Error('No updates provided to updatePredictionWorkspace'))
    }
    // Keep only certain keys
    updates = _.omit(updates, ['filenames'])
    dbRef.child(datasetDatabasePath).update(updates, resolve)
  })
}

export function addFilenamesToPredictionWorkspace({
  datasetID,
  key,
  filenames,
}) {
  return new Promise((resolve, reject) => {
    const datasetDatabasePath = `predictionWorkspaces/${datasetID}/${key}/filenames`
    console.log('Updating firebase', datasetDatabasePath)
    if (!datasetID || !key) {
      return reject(
        new Error('Missing params for addFilenamesToPredictionWorkspace')
      )
    }
    if (_.isEmpty(filenames)) {
      return reject(
        new Error('No updates provided to addFilenamesToPredictionWorkspace')
      )
    }

    dbRef.child(datasetDatabasePath).transaction(
      function (prevFilenames) {
        if (prevFilenames === null) {
          return filenames
        } else {
          // existing filenames, keep only unique
          const prevFilenamesArr = _.toArray(prevFilenames)
          const allFilenames = _.uniq([...prevFilenamesArr, ...filenames])
          return allFilenames.sort()
        }
      },
      function (error, committed, snapshot) {
        if (error) {
          console.log('Transaction failed abnormally!', error)
          reject(error())
        } else if (!committed) {
          console.warn('addFilenamesToPredictionWorkspace transaction aborted')
        }
        resolve(snapshot.val())
      }
    )
  })
}

export function removeFilenamesFromPredictionWorkspace({
  datasetID,
  key,
  filenames,
}) {
  return new Promise((resolve, reject) => {
    const datasetDatabasePath = `predictionWorkspaces/${datasetID}/${key}/filenames`
    console.log('Updating firebase', datasetDatabasePath)
    if (!datasetID || !key) {
      reject(
        new Error('Missing params for removeFilenamesFromPredictionWorkspace')
      )
    }
    if (_.isEmpty(filenames)) {
      reject(
        new Error(
          'No updates provided to removeFilenamesFromPredictionWorkspace'
        )
      )
    }

    dbRef.child(datasetDatabasePath).transaction(
      function (prevFilenames) {
        if (prevFilenames === null) {
          return []
        } else {
          // existing filenames, keep only unique
          const prevFilenamesArr = _.toArray(prevFilenames)
          const allFilenames = _.without(prevFilenamesArr, ...filenames)
          return allFilenames.sort()
        }
      },
      function (error, committed, snapshot) {
        if (error) {
          console.log('Transaction failed abnormally!', error)
          reject(error())
        } else if (!committed) {
          console.warn(
            'removeFilenamesFromPredictionWorkspace transaction aborted'
          )
        }
        resolve(snapshot.val())
      }
    )
  })
}

export function removePredictionWorkspace({ datasetID, key }) {
  return new Promise((resolve, reject) => {
    const datasetDatabasePath = `predictionWorkspaces/${datasetID}/${key}`
    console.log('Updating firebase', datasetDatabasePath)
    if (!datasetID || !key) {
      reject(new Error('Missing params for removePredictionWorkspace'))
    }
    dbRef.child(datasetDatabasePath).set(null, resolve)
  })
}

export async function getUserToken({ user }) {
  if (!user) return false
  const fbToken = await user.getIdToken(
    true // forceRefresh
  )
  return fbToken
}

export async function getDatasetImages({
  datasetID,
  asArray = true,
  filterEmpty = true,
}) {
  const datasetImages = await getDatabase({
    path: `datasetImages/${datasetID}`,
    asArray,
  })
  if (filterEmpty) {
    return _.uniq(datasetImages).filter(Boolean)
  } else {
    return datasetImages
  }
}

export async function createTrainingSession({
  datasetID,
  datasetIteration,
  valDatasetID,
  valDatasetIteration,
  trainValSplitRatio,
  maxIterations,
  mask = false,
  datasetSubsetCount = 0,
  classList = [],
  configFilename = serverSettings.configFilenames[1],
  balanced = false,
  imageProcessing = {
    clahe: false,
    backgroundSubtractionKNN: false,
  },
  baseLearningRate,
  imageSize = 'default', // 'default': 1333x800, '1080': 1920x1080
}) {
  console.log('Creating training session')

  const { configFilenames } = serverSettings

  if (!datasetID || !datasetIteration) {
    throw new Error('Missing parameter', { datasetID, datasetIteration })
  }

  if (configFilename && !configFilenames.includes(configFilename)) {
    throw new Error(`Config File '${configFilename}' not found`)
  }

  if (!valDatasetID || !valDatasetIteration) {
    valDatasetID = datasetID
    valDatasetIteration = datasetIteration
  }

  const trainingSessionID = generateID()
  const trainingSessionName = await generateName()
  const timestamp = getTimestamp()
  const useTrainValSplit =
    trainValSplitRatio &&
    valDatasetID === datasetID &&
    valDatasetIteration === datasetIteration
  const trainingSessionStatus = 'Initialised'
  const trainingSessionConfig = {
    trainingSessionID,
    trainingSessionName,
    timestamp,
    datasetID,
    useTrainValSplit,
    trainValSplitRatio,
    datasetIteration,
    datasetSubsetCount, // N random sample of annotations to use for training
    valDatasetID,
    valDatasetIteration,
    maxIterations,
    classList,
    configFilename,
    mask,
    status: trainingSessionStatus,
    balanced,
    imageProcessing,
    baseLearningRate,
    imageSize,
  }

  // Write to firebase
  const updatedTrainingSessionConfig = await updateTrainingSessionConfig({
    datasetID,
    trainingSessionID,
    updates: trainingSessionConfig,
  })

  return updatedTrainingSessionConfig
}

export async function setupTrainingSessionEvaluation({
  datasetID,
  trainingSessionID,
  valDatasetID,
  valDatasetIteration,
  checkpoint,
  strictClassFilter,
}) {
  console.log('Setting up training session evaluation')

  if (
    !datasetID ||
    !trainingSessionID ||
    !valDatasetID ||
    !valDatasetIteration
  ) {
    throw new Error('Missing parameter', {
      datasetID,
      trainingSessionID,
      valDatasetID,
      valDatasetIteration,
    })
  }

  const trainingSessionConfig = await getTrainingSessionConfig({
    datasetID,
    trainingSessionID,
  })

  if (!trainingSessionConfig) {
    throw new Error(
      `Couldn't get trainingSessionConfig: ${datasetID} ${trainingSessionID}`
    )
  }

  const evaluationID = generateID()

  // Add new evaluation to the evaluation list
  const thisEvaluation = {
    evaluationID,
    datasetID: valDatasetID,
    datasetIteration: valDatasetIteration,
    status: 'Initialised',
    created: new Date().toISOString(),
  }

  if (checkpoint) {
    thisEvaluation.checkpoint = parseInt(checkpoint, 10)
  }

  if (strictClassFilter) {
    thisEvaluation.strictClassFilter = true
  }

  // Write to firebase
  await updateTrainingSessionEvaluation({
    datasetID,
    trainingSessionID,
    evaluationID,
    updates: thisEvaluation,
  })

  return thisEvaluation
}

export const createDatasetGroup = async ({ name = 'A New Group' }) => {
  const id = generateID()
  const path = 'datasetGroups'
  const databaseRef = database.ref(`${dbEndpoint}/${path}`)
  await databaseRef.child(id).set({ name })
}

export const removeDatasetGroup = async ({ datasetGroupID }) => {
  if (!datasetGroupID) {
    return console.error('removeDatasetGroup', { datasetGroupID })
  }
  const path = `datasetGroups/${datasetGroupID}`
  const databaseRef = database.ref(`${dbEndpoint}/${path}`)
  // Keep only certain keys
  return await databaseRef.remove()
}

export const updateDatasetGroup = async ({ datasetGroupID, updates }) => {
  if (!datasetGroupID || !updates) {
    return console.error('updateDatasetGroup', { datasetGroupID, updates })
  }

  const path = `datasetGroups/${datasetGroupID}`
  const databaseRef = database.ref(`${dbEndpoint}/${path}`)
  // Keep only certain keys
  updates = _.pick(updates, ['name'])
  return await databaseRef.update(updates)
}

export const addDatasetToGroup = async ({ datasetID, datasetGroupID }) => {
  if (!datasetID) {
    return console.error('addDatasetToGroup', { datasetID })
  }

  return updateDataset({
    datasetID,
    updates: {
      datasetGroupID: datasetGroupID || false,
    },
  })
}

export const updateDatasetUser = async ({
  datasetID,
  userID,
  action = 'add',
}) => {
  console.log(`${action} user ${userID} to dataset ${datasetID}`)
  if (!datasetID || !userID) {
    return console.error('updateDatasetUser missing params', {
      datasetID,
      userID,
    })
  }

  const databaseRef = database.ref(`${dbEndpoint}/datasets/${datasetID}/users`)

  return new Promise((resolve, reject) => {
    databaseRef.transaction(
      function (prevUsers) {
        if (prevUsers === null) {
          if (action === 'add') {
            return [...userID]
          } else {
            return prevUsers
          }
        } else {
          const prevUsersArr = Object.values(prevUsers)
          const usersSet = new Set(prevUsersArr)
          if (action === 'add') {
            usersSet.add(userID)
          }
          if (action === 'remove') {
            usersSet.delete(userID)
          }
          const uniqueUsers = [...usersSet].sort()
          return uniqueUsers
        }
      },
      function (error, committed, snapshot) {
        if (error) {
          console.log('Transaction failed abnormally!', error)
          reject(error())
        } else if (!committed) {
          console.warn('addUserToDataset transaction aborted')
        }
        resolve(snapshot.val())
      }
    )
  })
}

export const sendPasswordResetEmail = ({ email }) => {
  return auth.sendPasswordResetEmail(email)
}

export const deletePrediction = async ({
  trainingSessionID,
  filename,
  jobId,
}) => {
  console.log(
    '⚡️: deletePrediction -> trainingSessionID',
    trainingSessionID,
    filename,
    jobId
  )
  const filenameResults = await getDatabase({
    path: `/predictions/${trainingSessionID}/results/${_.snakeCase(filename)}`,
  })

  // find multiple matches in case of duplicate jobIds
  const matches = Object.entries(filenameResults).filter(
    ([key, result]) => result.jobId === jobId
  )
  console.log(`⚡️: deletePrediction -> ${matches.length} matches`)

  // Add remove key to match object
  const updates = {
    removed: true,
  }

  // loop through each results match and add updates
  for (let i = 0; i < matches.length; i++) {
    const [key] = matches[i]
    const path = `/predictions/${trainingSessionID}/results/${_.snakeCase(
      filename
    )}/${key}`
    await dbRef.child(path).update(updates)
  }

  return
}

export const deleteSuggestedAnnotation = async ({
  datasetID,
  suggestedAnnotation = {},
}) => {
  if (datasetID && suggestedAnnotation?.id) {
    console.log(`🔥 Deleting suggested annotation`, suggestedAnnotation)

    const annotationRef = firestore
      .collection('datasetWorkspaceSuggestedAnnotations')
      .doc(datasetID)
      .collection('annotations')
      .doc(suggestedAnnotation.id)

    await annotationRef.delete()
    await calculateAndUpdateDatasetWorkspaceMultipleFilenamesMeta({
      datasetID,
      filenames: [suggestedAnnotation.filename],
      suggested: true,
    })
  }
  return
}

export const updateAnnotation = async ({
  datasetID,
  annotation,
  updates,
  userID,
}) => {
  if (_.isEmpty({ ...updates, ...annotation })) {
    return console.error('updateAnnotation: No updates provided')
  }
  if (!annotation?.id) {
    return console.error('updateAnnotation: No Annotation ID')
  }
  if (!userID) {
    return console.error('updateAnnotation: No userID')
  }
  if (!datasetID) {
    return console.error('updateAnnotation: No datasetID')
  }

  const pathString = `datasetWorkspaces/${datasetID}/annotations/${annotation.id}`

  const deleteActionRequested = updates?.deleted

  if (deleteActionRequested) {
    console.log(`🔥 Deleting annotation: ${pathString}`)

    const annotationRef = firestore
      .collection('datasetWorkspaces')
      .doc(datasetID)
      .collection('annotations')
      .doc(annotation.id)

    await annotationRef.delete()
  } else {
    console.log(`🔥 Updating annotation: ${pathString}`)

    let updatedAnnotation = {
      ...annotation,
      ...updates,
      user: userID,
      ts: getTimestamp(),
    }

    // stringify segmentation
    if (_.isArray(updatedAnnotation?.segmentation)) {
      updatedAnnotation.segmentation = JSON.stringify(
        updatedAnnotation.segmentation
      )
    }

    const annotationRef = firestore
      .collection('datasetWorkspaces')
      .doc(datasetID)
      .collection('annotations')
      .doc(annotation.id)

    await annotationRef.set(updatedAnnotation, { merge: true })
  }
  // update workspace filename
  const filename = annotation.filename
  await calculateAndUpdateDatasetWorkspaceMultipleFilenamesMeta({
    datasetID,
    filenames: [filename],
    addEdited: true,
  })
}

const convertSegmentation = segmentation => {
  if (!segmentation) {
    return null
  }
  if (_.isString(segmentation)) {
    return segmentation
  }
  return JSON.stringify(segmentation)
}

async function deleteWorkspaceAnnotations({ datasetID }) {
  console.log(`🔥 Deleting existing annotations for dataset ${datasetID}`)
  const collectionRef = firestore
    .collection('datasetWorkspaces')
    .doc(datasetID)
    .collection('annotations')

  const datasetWorkspaceAnnotationsSnapshot = await collectionRef.get()

  const docs = datasetWorkspaceAnnotationsSnapshot.docs.map(doc => {
    return doc
  })

  // chunk by 500 (max firebase batch request size)
  const docsChunked = _.chunk(docs, 500)
  await pMap(
    docsChunked,
    async docs => {
      const filenames = docs.map(doc => doc.data().filename)
      // Get a new write batch
      const batch = firestore.batch()
      docs.forEach(doc => {
        batch.delete(doc.ref)
      })

      // Commit the batch
      await batch.commit()

      // update workspace filename
      await calculateAndUpdateDatasetWorkspaceMultipleFilenamesMeta({
        datasetID,
        filenames,
        addEdited: true,
      })
    },
    { concurrency: 1 }
  )
}

const calculateAndUpdateDatasetWorkspaceMultipleFilenamesMeta = async ({
  datasetID,
  filenames,
  suggested = false,
  addEdited = false,
}) => {
  if (!datasetID || !filenames.length) {
    return console.error(
      'Cannot calculateAndUpdateDatasetWorkspaceMultipleFilenamesMeta',
      {
        datasetID,
        filenames,
      }
    )
  }

  const filenamesFiltered = _.uniq(filenames.filter(Boolean))

  // Get a new write batch
  const batch = firestore.batch()

  const mainCollection = suggested
    ? 'datasetWorkspaceSuggestedAnnotations'
    : 'datasetWorkspaces'
  const collectionRef = firestore
    .collection(mainCollection)
    .doc(datasetID)
    .collection('filenames')

  await pMap(
    filenamesFiltered,
    async filename => {
      // Get annotations
      const annotations = await getDatasetWorkspaceAnnotations({
        datasetID,
        filename,
        suggested,
      })

      // calculate filenameMeta
      const { annotatedFilenames } = calculateAnnotationsFilenameMeta({
        annotations,
      })

      const docRef = collectionRef.doc(filename)

      if (!annotatedFilenames.length) {
        // Delete filename reference
        // datasetWorkspaces/${datasetID}/filenames/${filename}

        console.log(
          `Deleting filename data at ${mainCollection}/${datasetID}/filenames/${filename}`
        )
        if (addEdited) {
          // add property to filename to signify edits made
          batch.set(docRef, { edited: true }, { merge: false })
        } else {
          batch.delete(docRef)
        }
        return
      }

      // returns an array, select first object in array, without filename property
      const { filename: metaFilename, ...meta } = annotatedFilenames[0] || {}

      if (addEdited) {
        // add property to filename to signify edits made
        meta.edited = true
      }
      // Update datasetWorkspaces/${datasetID}/filenames/${filename}
      console.log(
        `Updating filename data at datasetWorkspaces/${datasetID}/filenames/${filename}`,
        meta
      )
      batch.set(docRef, meta, { merge: false })

      return
    },
    { concurrency: 10 }
  )

  // Commit the batch
  await batch.commit()
  return
}

const calculateAnnotationsFilenameMeta = ({ annotations }) => {
  const annotationsByFilename = _.groupBy(annotations, 'filename')
  const annotatedFilenames = _.reduce(
    annotationsByFilename,
    (acc, filenameAnnos, filename) => {
      // [{
      //   [filename]: '',
      //    counts: {
      //      [className]: 1
      //    }
      // }]
      const meta = { filename, counts: {} }
      filenameAnnos.forEach(anno => {
        const className = anno.class
        const existingCount = _.get(meta.counts, `${className}`, 0)
        // increment class
        _.set(meta.counts, `${className}`, existingCount + 1)
      })
      acc.push(meta)
      return acc
    },
    []
  )
  return { annotatedFilenames }
}
