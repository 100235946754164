import './App.css'

import React, { Fragment, Suspense, useEffect, useState } from 'react'

import { Router, navigate } from '@reach/router'

import DatasetImport from './components/DatasetImport.js'
import DatasetSummaryPage from './components/DatasetSummaryPage'
import Loading from './components/Loading'
import Notification from './components/Notification'
import ServerStatus from './components/ServerStatus'
import User from './components/User'
import { auth, handleFirebaseDisconnect } from './firebase.js'

const NotFound = React.lazy(() => import('./components/NotFound'))
const Signin = React.lazy(() => import('./components/Signin'))
const Main = React.lazy(() => import('./components/Main'))
const VideoInput = React.lazy(() => import('./components/VideoInput'))
const Training = React.lazy(() => import('./components/Training'))
const Predict = React.lazy(() => import('./components/Predict'))
const Profile = React.lazy(() => import('./components/Profile'))
const DatasetManager = React.lazy(() => import('./components/DatasetManager'))
const DatasetVideoPreview = React.lazy(() =>
  import('./components/DatasetVideoPreview')
)

const App = () => {
  const [user, setUser] = useState(null)
  const [connected, setConnected] = useState(true)
  const [firebaseConnection, setFirebaseConnection] = useState(false)

  useEffect(() => {
    auth.onAuthStateChanged(async user => {
      setUser(user)

      if (!user) {
        // User is signed out.
        navigate('/login')
      }
    })
    handleFirebaseDisconnect(connection => setFirebaseConnection(connection))
  }, [setFirebaseConnection, setUser])

  return (
    <Fragment>
      <User user={user} />
      <Notification />
      <Suspense fallback={<Loading fullscreen />}>
        <Router>
          <Main
            path="/"
            user={user}
            connected={connected}
            firebaseConnection={firebaseConnection}
          />
          <DatasetImport
            path="/datasetImport/:datasetID"
            user={user}
            closePopup={() => navigate('/')}
          />
          <DatasetVideoPreview
            path="/dataset-video/:datasetID/:filename"
            user={user}
            closePopup={() => navigate('/')}
          />
          <DatasetManager path="/datasets" user={user} />
          <Signin path="/login" user={user} />
          <Profile path="/profile" user={user} />
          <ServerStatus path="/status" user={user} />
          <Training path="/training/*" user={user} />
          <Predict path="/predict/*" user={user} />
          <VideoInput
            path="/upload"
            user={user}
            closePopup={() => navigate('/')}
          />
          <DatasetSummaryPage
            path="/datasets/:datasetID/:iterationID"
            closePopup={() => navigate('/')}
          />
          <NotFound default />
        </Router>
      </Suspense>
    </Fragment>
  )
}

export default App
