import _ from 'lodash'
import Papa from 'papaparse'
import React, { useEffect, useMemo, useState } from 'react'

import { requestImportWorkspaceAnnotations } from '../request'
import Identicon from './Identicon'
import ProgressBar from './ProgressBar'

export const ImportDatasetModal = ({
  setShowModal,
  datasetID,
  datasetSlug,
  user,
}) => {
  const close = () => setShowModal(false)
  const [isLoading, setIsLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const [importError, setImportError] = useState()
  const [progress, setProgress] = useState()
  const [csvFile, setCsvFile] = useState()

  const { csvError, csvParsed } = useCsvParsed({ csvFile })
  const {
    validatedAnnotations,
    validationError,
    invalidAnnotations,
  } = useValidatedAnnotations({
    parsedAnnotations: csvParsed,
  })
  const { chunkedCsvFileContent } = useChunkedAnnotations({
    validatedAnnotations,
    chunkSize: 10000,
  })

  async function handleSubmit(e) {
    e.preventDefault()
    if (user) {
      setProgress(0)
      setIsLoading(true)
      setImportError(null)
      setSuccessMessage(null)
      try {
        await handleSequentialImportRequests({
          chunkedCsvFileContent,
          datasetID,
          user,
          setProgress,
        })
        setSuccessMessage(
          `Successfully imported ${validatedAnnotations.length} annotations`
        )
      } catch (err) {
        console.log(err)
        setImportError(err)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const canUpload =
    !!validatedAnnotations?.length &&
    !successMessage &&
    !validationError &&
    !csvError

  return (
    <form onSubmit={e => e.preventDefault()} className="modal is-active">
      <fieldset disabled={isLoading}>
        <div className="modal-background" onClick={close} />
        <div className="modal-card" style={{ minHeight: '70vh' }}>
          <header className="modal-card-head">
            <p className="modal-card-title">Import annotations</p>
            <button className="delete" aria-label="close" onClick={close} />
          </header>
          <section className="modal-card-body">
            <p className="notification is-warning is-light">
              Warning: annotations in the current workspace will be replaced
            </p>

            <div className="block mb-3">
              <div className="tag">
                <Identicon seed={datasetID} style={{ marginRight: 10 }} />
                <span>{datasetSlug}</span>
              </div>
            </div>

            <div className="field">
              <label className="label">Import CSV</label>
              <div className="control">
                <input
                  type="file"
                  accept="text/csv"
                  name="csvFile"
                  multiple={false}
                  onChange={e => {
                    setCsvFile(e.target.files[0])
                  }}
                />
              </div>
            </div>

            {(!!validationError?.message || !!csvError) && (
              <div className="notification is-danger">
                <div>
                  Error parsing CSV:{' '}
                  {validationError?.message || csvError?.message}
                </div>
                {!!invalidAnnotations?.length && (
                  <pre
                    style={{
                      maxHeight: 400,
                      maxWidth: `100%`,
                      overflow: 'auto',
                    }}
                  >
                    <code>
                      {invalidAnnotations.map((anno, index) => (
                        <div key={`invalid-${index}`}>
                          <strong>({anno.message})</strong>
                          {Object.entries(anno)
                            .filter(([key]) => !!key && key !== 'message')
                            .map(([key, value], index) => (
                              <span
                                key={key}
                                style={{ marginLeft: '1em' }}
                                title={`${key}: ${value}`}
                              >
                                <strong>{key}:</strong>{' '}
                                {`${value}`.substring(0, 10)}
                              </span>
                            ))}
                        </div>
                      ))}
                    </code>
                  </pre>
                )}
              </div>
            )}

            {canUpload && (
              <div className="field">
                <div className="control">
                  <button
                    className={`button is-success ${
                      isLoading ? 'is-loading' : ''
                    }`}
                    onClick={handleSubmit}
                  >
                    Import {validatedAnnotations?.length} annotations
                  </button>
                </div>
              </div>
            )}

            {!!progress && <ProgressBar progress={progress} />}

            {!!successMessage && (
              <div className="notification is-success">{successMessage}</div>
            )}

            {!!importError && (
              <div className="notification is-danger">
                <strong>Import Error</strong>
                <br />
                {importError.message}
              </div>
            )}
          </section>
        </div>
      </fieldset>

      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={close}
      />
    </form>
  )
}

function useCsvParsed({ csvFile }) {
  const [csvFileContent, setCsvFileContent] = useState()
  const [csvParsed, setCsvParsed] = useState(null)
  const [csvError, setCsvError] = useState(null)

  useEffect(() => {
    if (csvFile) {
      // Read CSV file
      const csvReader = new FileReader()
      csvReader.onload = e => {
        const text = e.target.result
        setCsvFileContent(text)
      }
      csvReader.readAsText(csvFile)
    }
  }, [csvFile])

  useEffect(() => {
    if (csvFileContent) {
      // Parse CSV file
      Papa.parse(csvFileContent, {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        complete: results => {
          setCsvParsed(results.data)
        },
        error: err => {
          setCsvError(err)
        },
      })
    }
  }, [csvFileContent])

  return { csvParsed, csvError, csvFileContent }
}

function validateAnnotation(anno) {
  if (!_.isString(anno.id) || !anno.id.length) {
    return { valid: false, message: '"id" is required' }
  }
  if (!_.isString(anno.className)) {
    return { valid: false, message: '"className" is required' }
  }
  if (!_.isString(anno.filename)) {
    return { valid: false, message: '"filename" is required' }
  }
  return { valid: true }
}

function useValidatedAnnotations({ parsedAnnotations }) {
  const [validatedAnnotations, setValidatedAnnotations] = useState()
  const [validationError, setValidationError] = useState()

  useEffect(() => {
    if (parsedAnnotations) {
      setValidationError(null)
      const validated = parsedAnnotations.filter(
        anno => validateAnnotation(anno).valid
      )

      // If any annotation is invalid, set error
      if (validated.length !== parsedAnnotations.length) {
        setValidationError(
          new Error(
            `${parsedAnnotations.length - validated.length} invalid annotations`
          )
        )
        setValidatedAnnotations(validated)
      } else {
        setValidationError(null)
        setValidatedAnnotations(validated)
      }
    }
  }, [parsedAnnotations])

  const invalidAnnotations = useMemo(() => {
    if (validationError) {
      return parsedAnnotations
        .map(anno => {
          return {
            ...anno,
            ...validateAnnotation(anno),
          }
        })
        .filter(anno => !anno.valid)
    }
    return []
  }, [parsedAnnotations, validationError])

  return { validatedAnnotations, validationError, invalidAnnotations }
}

function useChunkedAnnotations({
  validatedAnnotations,
  chunkSize = 1000, // 1000 rows
}) {
  const chunkedAnnotations = useMemo(() => {
    if (validatedAnnotations?.length) {
      const chunks = _.chunk(validatedAnnotations, chunkSize)
      return chunks
    }
    return []
  }, [validatedAnnotations, chunkSize])

  const chunkedCsvFileContent = useMemo(() => {
    if (chunkedAnnotations?.length) {
      const chunkedCsvStrings = chunkedAnnotations.map(annos =>
        Papa.unparse(annos, {
          header: true,
        })
      )
      return chunkedCsvStrings
    }
    return []
  }, [chunkedAnnotations])

  return { chunkedAnnotations, chunkedCsvFileContent }
}

async function handleSequentialImportRequests({
  chunkedCsvFileContent,
  user,
  datasetID,
  setProgress,
}) {
  let index = 0
  for (let csvFileContent of chunkedCsvFileContent) {
    // Only delete annotations if this is the first chunk
    const message = `Requesting import to dataset workspace ${datasetID} chunk ${
      index + 1
    }`
    console.log(message)
    console.time(message)
    const deleteExistingAnnotations = index === 0
    const res = await requestImportWorkspaceAnnotations({
      user,
      datasetID,
      deleteExistingAnnotations,
      csvString: csvFileContent,
    })
    const data = await res.json()

    index += 1
    const progress = index / chunkedCsvFileContent.length
    setProgress(progress)
    console.timeEnd(message)

    if (res.status !== 200) {
      throw new Error(data.error)
    }
  }
}
