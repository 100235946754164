import React from 'react'
import Avatars from '@dicebear/avatars'
import identiconSprites from '@dicebear/avatars-identicon-sprites'
import gridySprites from '@dicebear/avatars-gridy-sprites'
import Tippy from '@tippy.js/react'
import './Identicon.css'

const Identicon = ({ seed, isModel, style = {}, ...props }) => {
  if (!seed) return null

  const options = { background: 'transparent' }
  let avatars = new Avatars(
    isModel ? gridySprites(options) : identiconSprites(options)
  )
  let svg = avatars.create(seed)

  return (
    <Tippy content={seed} interactive>
      <figure
        className="Identicon"
        data-testid="Identicon"
        style={{ ...style }}
        dangerouslySetInnerHTML={{ __html: svg }}
        {...props}
      />
    </Tippy>
  )
}

export default Identicon
